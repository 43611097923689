import {
    GetOrderAndLocationQuery,
    Maybe,
    Payment,
    PaymentLineItem,
    PaymentState,
    ServicePaymentType,
} from 'utils/generated';
import { OrderContextValue } from './OrderProvider';

export type OverviewPaymentItem = {
    name: string;
    amount: number;
    tipAmount: number;
    serviceAmount: number;
    parties?: number | null;
    lineItems?: Maybe<Array<PaymentLineItem>>;
    lastFour?: string;
    state: PaymentState;
};

// This is used to get service paid in transaction as it is not presently returned from BE.
export const getServiceForPayment = (payment: Payment, orderServiceCharge?: number): number => {
    const paymentAmount = payment.amount ?? 0;
    // Returns over payment by taking the payment amount away from the total for all line items
    if (payment.lineItems) {
        return (
            paymentAmount -
            (payment.lineItems?.reduce(
                (lineItemTotal, lineItem) => lineItemTotal + lineItem.amount * lineItem.quantity,
                0
            ) ?? 0)
        );
    }
    // Returns service charge divided by parties
    if (orderServiceCharge && payment.parties) {
        return orderServiceCharge / payment.parties;
    }
    // Returns full service charge amount for order
    if (orderServiceCharge && !payment.parties && !payment.lineItems) return orderServiceCharge;
    return 0;
};

export const getConcatenatedPayments = (order: GetOrderAndLocationQuery['getOrder']): OverviewPaymentItem[] => {
    if (!order?.payments && !order?.posPayments) return [];
    const tablePayPayments = order.payments
        .filter((x) => x.state === PaymentState.Completed || x.amount <= 0)
        .map((x) => ({
            name: x.consumerName ?? 'Someone',
            amount: x.amount,
            tipAmount: x.servicePayment?.type === ServicePaymentType.Tip ? x.servicePayment?.amount : 0,
            serviceAmount: getServiceForPayment(x as Payment, order.serviceCharge?.amount),
            parties: x.parties,
            lineItems: x.lineItems,
            lastFour: x.cardDetails?.last4,
            state: x.state,
        }));

    if (!order.posPayments) return tablePayPayments;

    const posPayments = order.posPayments
        .filter((x) => x.external)
        .map((x) => ({
            name: x.paymentMethod,
            amount: x.amount,
            tipAmount: 0,
            serviceAmount: 0,
            state: PaymentState.Completed,
        }));

    return posPayments.concat(tablePayPayments);
};

export const getSubtotal = (order: GetOrderAndLocationQuery['getOrder']): number => {
    if (!order) return 0;
    if (!order.serviceCharge?.amount) return order.amount;
    return order.amount - order.serviceCharge.amount;
};

export const getTipsPaid = (payments: OverviewPaymentItem[]): number => {
    if (payments.length > 0) {
        return payments.reduce((acc, payment) => payment.tipAmount + acc, 0);
    }
    return 0;
};

export const getBalance = (payments: OverviewPaymentItem[], orderAmount: number): number => {
    if (payments.length > 0) {
        return (
            orderAmount -
            (payments.reduce((acc, payment) => {
                return payment.amount - payment.tipAmount + acc;
            }, 0) ?? 0)
        );
    }
    return orderAmount;
};

export const convertCentsToDisplay = (cents: number) => (cents > 0 ? cents / 100 : 0);

export const calculateTipAtPercentage = (tipPercentage: number, amount: number) =>
    Math.floor(amount * (tipPercentage / 100));

export interface FormattedLineItem {
    name: string;
    singleItemAmount: number;
    purchasedQuantity: number;
    remainingQuantity: number;
    originalQuantity: number;
    childItems: Array<{
        amount: number;
        name: string;
        quantity: number;
    }>;
}

export const formatLineItemsWithQuantities = (
    order: OrderContextValue['order'],
    payments: OverviewPaymentItem[]
): Record<string, FormattedLineItem> => {
    const items: Record<string, FormattedLineItem> = {};

    order.lineItems.forEach((item) => {
        if (item.quantity > 0 && item.posId != null) {
            items[item.posId] = {
                name: item.name,
                singleItemAmount: item.amount,
                purchasedQuantity: 0,
                remainingQuantity: item.quantity,
                childItems: item.childItems,
                originalQuantity: item.quantity,
            };
        }
    });

    payments.forEach((payment) => {
        if (payment.lineItems) {
            payment.lineItems.forEach((item) => {
                if (item.posId != null && items[item.posId]) {
                    items[item.posId].purchasedQuantity += item.quantity;
                    items[item.posId].remainingQuantity -= item.quantity;
                }
            });
        }
    });

    return items;
};
