import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, HStack, VStack } from '@chakra-ui/react';

interface Props {
    onClick: () => void;
    children: React.ReactNode;
    disabled?: boolean;
}

function Selector({ onClick, children, disabled }: Props) {
    return (
        <Box
            cursor="pointer"
            color={disabled ? 'grey' : 'black'}
            w="full"
            border="2px solid #E6E6E6"
            borderRadius="2xl"
            onClick={onClick}
            as="button"
        >
            <HStack m="3" justify="space-between">
                <VStack spacing="0" align="flex-start">
                    {children}
                </VStack>
                <ChevronRightIcon />
            </HStack>
        </Box>
    );
}

export default Selector;

Selector.defaultProps = {
    disabled: false,
};
