import { Box, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import CurrencyFormat from '../../components/CurrencyFormat';
import { GetOapOrderForReceiptQuery } from '../../utils/generated';
import colors from '../../utils/theme/colors';

interface Props {
    lineItems: NonNullable<GetOapOrderForReceiptQuery['getCompletedOrder']>['lineItems'];
}

function ReceiptItems({ lineItems }: Props) {
    return (
        <Box data-cy="orderItemGrid" overflow="auto">
            <Grid px="4" py="4" templateColumns="1fr 10fr 1fr" gap="1">
                {lineItems?.map((lineItem) => (
                    <Fragment key={`line-item-${lineItem.name}`}>
                        <Text fontWeight="500" fontSize="sm">
                            {lineItem.quantity}x
                        </Text>

                        <Text fontWeight="500" fontSize="sm">
                            {lineItem.name}
                        </Text>
                        <GridItem>
                            <HStack justifyContent="space-between">
                                <Text w="full" fontWeight="600" fontSize="sm" align="right">
                                    <CurrencyFormat amount={lineItem.quantity * lineItem.amount} />
                                </Text>
                            </HStack>
                        </GridItem>
                        <GridItem colSpan={2} colStart={2}>
                            {lineItem.childItems.map((childItem, childItemIndex) => (
                                <Text
                                    key={childItem.name + String(childItemIndex)}
                                    gridColumnStart="2"
                                    color={colors.neutral.n600}
                                    fontSize="sm"
                                >
                                    {childItem.quantity > 1 && `${childItem.quantity}x`} {childItem.name}
                                </Text>
                            ))}
                        </GridItem>
                    </Fragment>
                ))}
            </Grid>
        </Box>
    );
}

export default ReceiptItems;
