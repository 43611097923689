import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Currency, useGetOrderForReceiptQuery } from '../../utils/generated';
import LoadingReceipt from './LoadingReceipt';
import Receipt from './Receipt';
import { PlaceHolderReceipt } from './placeholder-receipt/PlaceholderReceipt';

function ReceiptContainer() {
    const { orderId } = useParams() as Record<string, string>;

    const [searchParams] = useSearchParams();

    const orderRef = searchParams.get('r') ?? undefined;
    const amount = searchParams.get('a');
    const currency = searchParams.get('c');

    // On error handler added to options to override global error handling which toasts on other routes.
    const { data, isFetching } = useGetOrderForReceiptQuery(
        {
            orderId,
        },
        {
            onError: (e) => e,
        }
    );

    if (isFetching && !data) {
        return <LoadingReceipt />;
    }

    const qrCodeValue = `${window.location.origin}/r2/${orderId}?&r=${orderRef}&a=${amount}&c=${currency}`;

    const order = data?.getStoreOrder;

    const currencyFromParams = Currency[currency as keyof typeof Currency] ?? undefined;

    if (!order) {
        return (
            <PlaceHolderReceipt
                amount={amount}
                currencyFromParams={currencyFromParams}
                orderRef={orderRef ?? null}
                qrCodeValue={qrCodeValue}
            />
        );
    }

    return <Receipt order={order} />;
}

export default ReceiptContainer;
