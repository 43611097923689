import React from 'react';
import { Navigate } from 'react-router-dom';
import { Chance } from 'chance';

function DemoReceiptQR() {
    const chance = new Chance();
    const demoUuid = chance.guid({ version: 4 });

    return (
        <Navigate
            to={`/posOrder?posSystem=Dummy&locationId=9e2577d4-d6c9-4bb0-86ac-c3d100708d39&orderId=${demoUuid}`}
        />
    );
}

export default DemoReceiptQR;
