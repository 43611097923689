import React, { useState } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import {
    PaymentRequest,
    PaymentRequestOptions,
    PaymentRequestPaymentMethodEvent,
    StripeError,
} from '@stripe/stripe-js/types/stripe-js';
import { useSetState, useStateData } from 'providers/AppStateProvider';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/layout';
import { PayOutletContext } from 'pages/pay/Pay';
import { captureBreadcrumb, stripeBreadcrumb } from 'helpers/sentryLogger';
import StripePaymentError from 'helpers/StripePaymentError';
import { errorToast } from 'helpers/ToastHelper';

interface Props {
    paymentInProgressCallback: (value: boolean) => void;
}

function CheckoutByWallet({ paymentInProgressCallback }: Props) {
    const navigate = useNavigate();
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
    const { state } = useStateData();
    const { setPaymentAuthorised } = useSetState();
    const { stripePaymentIntent } = useOutletContext<PayOutletContext>();
    const params = useParams();

    const { merchantId, locationId, orderId } = params;

    const orderUrl = `/merchant/${merchantId}/location/${locationId}/order/${orderId}`;

    const stripe = useStripe();

    const setupPaymentRequest = () => {
        const { merchant, order, payment } = state ?? {};
        if (stripe && state && merchant && order && payment) {
            const submitWalletPaymentToStripe = async (ev: PaymentRequestPaymentMethodEvent) => {
                try {
                    const { error, paymentIntent } = await stripe.confirmCardPayment(
                        stripePaymentIntent.clientSecret,
                        { payment_method: ev.paymentMethod.id },
                        { handleActions: false }
                    );
                    captureBreadcrumb(
                        stripeBreadcrumb({
                            stripeMethodName: 'confirmCardPayment',
                            status: 'initial',
                        })
                    );
                    if (error) {
                        const { message: description } = new StripePaymentError(
                            error,
                            state?.merchant?.countryCode,
                            params
                        );
                        errorToast({ description });
                        paymentInProgressCallback(false);
                        ev.complete('fail');
                        return;
                    }

                    ev.complete('success');
                    if (paymentIntent?.status === 'requires_action') {
                        // Let Stripe.js handle the rest of the payment flow.
                        const result = await stripe.confirmCardPayment(stripePaymentIntent.clientSecret);
                        captureBreadcrumb(
                            stripeBreadcrumb({
                                stripeMethodName: 'confirmCardPayment',
                                status: result?.paymentIntent?.status ?? 'Failed to confirm',
                            })
                        );
                        if (result.error) {
                            const { message: description } = new StripePaymentError(
                                result.error,
                                state?.merchant?.countryCode,
                                params
                            );
                            errorToast({ description });
                            paymentInProgressCallback(false);
                        } else {
                            setPaymentAuthorised();
                            navigate(`${orderUrl}/${stripePaymentIntent.id}/processing`, { replace: true });
                        }
                    } else {
                        setPaymentAuthorised();
                        navigate(`${orderUrl}/${stripePaymentIntent.id}/processing`, { replace: true });
                    }
                } catch (error) {
                    ev.complete('fail');
                    paymentInProgressCallback(false);
                    const { message: description } = new StripePaymentError(
                        error as StripeError,
                        state?.merchant?.countryCode,
                        params
                    );
                    errorToast({ description });
                }
            };

            const paymentRequestOptions = (): PaymentRequestOptions => {
                return {
                    country: merchant.countryCode,
                    currency: order.currency.toLocaleLowerCase(),
                    total: { label: merchant.businessName, amount: payment.amount },
                    requestPayerName: true,
                    requestPayerEmail: false,
                };
            };

            const getPaymentRequest = () => {
                const options = paymentRequestOptions();
                return { pr: stripe.paymentRequest(options) };
            };

            const { pr } = getPaymentRequest();
            pr.canMakePayment().then((result) => {
                if (result) {
                    setPaymentRequest(pr);
                }
            });
            pr.on('paymentmethod', async (ev) => submitWalletPaymentToStripe(ev));
        }
    };

    if (!paymentRequest) {
        setupPaymentRequest();
    }

    if (paymentRequest) {
        return (
            <Box maxW="md" w="100%">
                <PaymentRequestButtonElement options={{ paymentRequest }} />
            </Box>
        );
    }
    return null;
}

export default CheckoutByWallet;
