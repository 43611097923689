// This is temporary to deal with the state of code

import _ from 'lodash';
import { OgPaymentInput } from 'typings/AppStateProvider';
import { SplitItems } from 'typings/splitItem';
import { OrderPaymentType, ServicePaymentType, Maybe, PaymentLineItem, Payment, OrderV0 } from 'utils/generated';

const sum = (a: number, b: number) => a + b;

export default class OrderHelper {
    order: OrderV0;

    constructor(order: OrderV0) {
        this.order = order;
    }

    hasDiscount() {
        return this.order.hasDiscounts === true;
    }

    lineItemsDetails(): SplitItems {
        const orderLineItems: SplitItems = this.order?.lineItems.reduce(
            (acc, item) =>
                Object.assign(acc, {
                    [item.posId!]: {
                        name: item.name,
                        singleItemAmount: item.amount,
                        purchasedQuantity: 0,
                        remainingQuantity: item.quantity,
                        childItems: item.childItems,
                    },
                }),
            {}
        );
        this.order?.payments?.forEach((payment) => {
            payment?.lineItems?.forEach((lineItem) => {
                if (orderLineItems) {
                    orderLineItems[lineItem.posId].purchasedQuantity += lineItem.quantity;
                    orderLineItems[lineItem.posId].remainingQuantity -= lineItem.quantity;
                }
            });
        });

        return orderLineItems;
    }

    subtotal(): number {
        return this.order.amount - (this.order.serviceCharge?.amount ?? 0);
    }

    serviceTotal(): number {
        let serviceTotal = this.order.serviceCharge?.amount ?? 0;

        serviceTotal += this.payments()
            .map((x) => x.serviceAmount)
            .reduce(sum, 0);

        return serviceTotal;
    }

    // The one above should really be called TIP total as payments will not have service payments now.
    serviceChargePaidTotal() {
        const serviceChargeInPayment = (payment: Payment) =>
            payment.amount -
            (payment.lineItems?.reduce(
                (lineItemTotal, lineItem) => lineItemTotal + lineItem.amount * lineItem.quantity,
                0
            ) ?? 0);

        return this.order.payments.reduce((acc, payment) => serviceChargeInPayment(payment) + acc, 0);
    }

    unpaidItemsTotalQuantity(selectedItemCount: number): number {
        return this.numberOfItems() - this.numberOfItemsPaid() - selectedItemCount;
    }

    serviceChargeDue(selectedItemsTotal: number): number {
        if (!this.order.serviceCharge || this.order.serviceCharge?.amount === 0) return 0;

        if (selectedItemsTotal === 0) return 0;

        const paymentTotalWithoutServiceCharge = this.paymentTotal() - this.serviceChargePaidTotal();
        const itemAmountToPayTotal = this.subtotal() - paymentTotalWithoutServiceCharge;
        // If remaining balance would be paid then remaining service charge is due
        if (selectedItemsTotal === itemAmountToPayTotal)
            return this.serviceTotal() - this.serviceChargePaidTotal() ?? 0;

        // Not final payment so calculate the proportion of service charge due
        const percentageOfSale = selectedItemsTotal / this.subtotal();
        const proportionOfServiceDue = Math.floor(this.serviceTotal() * percentageOfSale);

        return proportionOfServiceDue;
    }

    payments(): {
        title: string;
        amount: number;
        serviceAmount: number;
        parties?: number;
        lineItems?: Maybe<Array<PaymentLineItem>> | undefined;
        lastFour?: string;
    }[] {
        const magicPayPayments = this.order.payments.map((x) => ({
            title: x.consumerName ?? 'Magic Pay',
            amount: x.amount,
            serviceAmount: x.servicePayment?.type === ServicePaymentType.Tip ? x.servicePayment?.amount : 0,
            parties: x.parties,
            lineItems: x.lineItems,
            lastFour: x.cardDetails?.last4,
        }));

        const posPayments = this.order?.posPayments
            ?.filter((x) => x.external)
            .map((x) => ({
                title: x.paymentMethod,
                amount: x.amount,
                serviceAmount: 0,
            }));

        return _.concat(magicPayPayments, posPayments || []);
    }

    numberOfpartyMembersPaid() {
        return this.payments()
            .map((x) => x.parties ?? 0)
            .reduce(sum, 0);
    }

    numberOfItemsPaid() {
        return this.payments()
            .map((x) => x.lineItems?.reduce((acc, lineItem) => lineItem.quantity + acc, 0) ?? 0)
            .reduce(sum, 0);
    }

    numberOfItems() {
        return (
            this.order.lineItems
                ?.filter((item) => item.amount > 0)
                .reduce((acc, lineItem) => lineItem.quantity + acc, 0) ?? 0
        );
    }

    numberOfpartyMembersToPay() {
        const partySize = this.order.orderPaymentIntention?.partySize;
        if (partySize) return partySize - this.numberOfpartyMembersPaid();
        return undefined;
    }

    orderHasPartyPayments() {
        return this.order.orderPaymentIntention?.orderPaymentType === OrderPaymentType.Party;
    }

    orderHasItemPayment() {
        return this.order.orderPaymentIntention?.orderPaymentType === OrderPaymentType.Item;
    }

    lastPayment() {
        if (this.order.payments.length > 0) {
            const [lastPayment] = this.order.payments.slice(-1);
            return lastPayment;
        }
        return undefined;
    }

    remainingLineItems() {
        const items = this.lineItemsDetails();

        if (this.order.orderPaymentIntention?.orderPaymentType === OrderPaymentType.Item) {
            return Object.keys(items)
                .map((itemid) => ({
                    posId: itemid,
                    amount: items[itemid].singleItemAmount,
                    quantity: items[itemid].remainingQuantity,
                }))
                .filter((item) => item.quantity !== 0);
        }
        return undefined;
    }

    servicePayment() {
        return this.order?.serviceCharge
            ? {
                  amount: this.order.serviceCharge.amount - this.serviceChargePaidTotal(),
                  type: ServicePaymentType.ServiceCharge,
              }
            : undefined;
    }

    paymentInput(merchantId: string, locationId: string): OgPaymentInput {
        return {
            amount: this.totalDue(),
            currency: this.order.currency,
            merchantId,
            locationId,
            orderId: this.order.id,
            parties: this.numberOfpartyMembersToPay(),
            lineItems: this.remainingLineItems(),
            orderPaymentIntention: {
                orderPaymentType: this.order.orderPaymentIntention?.orderPaymentType ?? OrderPaymentType.Full,
                // This needs to be checked as the API returns party size as null if orderPaymentType is OrderPaymentType.Item. The ?? check will replace null with undefined which is accepted.
                partySize: this.order.orderPaymentIntention?.partySize ?? undefined,
            },
            servicePayment: this.servicePayment(),
        };
    }

    paymentTotal(): number {
        return this.payments()
            .map((x) => x.amount)
            .reduce(sum, 0);
    }

    totalDue(): number {
        return this.subtotal() + this.serviceTotal() - this.paymentTotal();
    }
}
