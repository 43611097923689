import React from 'react';
import { useStateData } from 'providers/AppStateProvider';
import { Location } from 'utils/generated';
import { useNavigate } from 'react-router-dom';
import NoMenu from './NoMenu';

function LocationMenu() {
    const { state } = useStateData();
    const { menuUrl } = state?.location as Location;
    const navigate = useNavigate();
    if (menuUrl) {
        (() => {
            window.location.replace(menuUrl);
        })();
        navigate(-1);
    }
    return <NoMenu />;
}

export default LocationMenu;
