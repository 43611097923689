import React from 'react';
import useSplitInEqualParts from 'helpers/useSplitInEqualParts';
import { useStateData } from 'providers/AppStateProvider';
import { useIntl } from 'react-intl';
import { Currency, OrderV0 } from 'utils/generated';
import ErrorAlert from './ErrorAlert';

// Stripe minimum spends could be found listed here 23/02/22 https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts
const MINIMUM = {
    GBP: 30,
    USD: 50,
    EUR: 50,
    BRL: 50,
};

// To avoid adding all currencies to the above enum, this will default to a sensible value
export const getMinimum = (currency: Currency | undefined) => (currency && MINIMUM[currency]) || 100;

interface Props {
    children: React.ReactNode;
}

const convertCents = (cents: number) => (cents > 0 ? cents / 100 : 0);

function MinimumSpendButtonWrapper({ children }: Props) {
    const { state } = useStateData();
    const order = state?.order as OrderV0;

    const { amountDueForSelected } = useSplitInEqualParts();
    const intl = useIntl();

    const minimum = getMinimum(state?.order?.currency);

    const isBelowMinimum = amountDueForSelected < minimum;

    const childrenWithProps = React.Children.map(children, (child) => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { disabled: isBelowMinimum });
        }
        return child;
    });

    return (
        <>
            {amountDueForSelected > 0 && isBelowMinimum && (
                <ErrorAlert
                    message={`Minimum spend is ${intl.formatNumber(convertCents(minimum), {
                        style: 'currency',
                        currencySign: 'accounting',
                        currency: order.currency,
                    })}`}
                />
            )}
            {childrenWithProps}
        </>
    );
}

export default MinimumSpendButtonWrapper;
