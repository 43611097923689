import { matchPath, useLocation, useResolvedPath } from 'react-router-dom';

export enum MATCH_ROUTES {
    ORDER = '/merchant/:merchantId/location/:locationId/order/:orderId',
    PAY_SELECT = '/merchant/:merchantId/location/:locationId/order/:orderId/pay',
    SPLIT_METHOD = '/merchant/:merchantId/location/:locationId/order/:orderId/split',
}

function useCurrentPath(path: MATCH_ROUTES) {
    const location = useLocation();
    const currentPath = useResolvedPath(location.pathname);
    const isCurrentPath = !!matchPath({ path, end: true }, currentPath.pathname);
    return { isCurrentPath };
}

export default useCurrentPath;
