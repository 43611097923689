import { Button, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import QRCode from 'react-qr-code';
import React from 'react';
import { Currency } from 'utils/generated';
import { FormattedOptionalCurrency } from '../../../components/CurrencyFormat';
import Card from '../../../components/Card';
import ReceiptPage from '../ReceiptPage';

export function PlaceHolderReceipt({
    orderRef,
    qrCodeValue,
    amount,
    currencyFromParams,
}: {
    orderRef: string | null;
    qrCodeValue: string;
    amount: string | null;
    currencyFromParams: Currency;
}) {
    return (
        <ReceiptPage internalReference={orderRef}>
            <VStack h="full" gap="5">
                <VStack>
                    {amount != null && (
                        <>
                            <Text mt="16" textAlign="center">
                                Amount
                            </Text>
                            <Heading mb="2" align="center" size="3xl">
                                <FormattedOptionalCurrency amount={+amount} currency={currencyFromParams} />
                            </Heading>
                        </>
                    )}
                </VStack>
                <Heading size="lg" fontWeight="700" as="h2" textAlign="center">
                    We are preparing your collection slip.
                </Heading>

                <Card stretch={false}>
                    <QRCode
                        size={256}
                        style={{ height: 'auto', maxWidth: '150px', width: '100%', margin: '0' }}
                        value={qrCodeValue}
                        viewBox="0 0 256 256"
                    />
                </Card>

                <HStack mt="16" align="center">
                    <Button onClick={() => window.location.reload()}>Refresh</Button>
                </HStack>
            </VStack>
        </ReceiptPage>
    );
}
