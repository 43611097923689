import React from 'react';
import { Outlet } from 'react-router-dom';
import OrderProvider from './OrderProvider';
import DisplayDataProvider from './DisplayDataProvider';
import BasketProvider from './basket-provider/BasketProvider';

function OpContexts() {
    return (
        <OrderProvider>
            <DisplayDataProvider>
                <BasketProvider>
                    <Outlet />
                </BasketProvider>
            </DisplayDataProvider>
        </OrderProvider>
    );
}

export default OpContexts;
