import { useStateData } from 'providers/AppStateProvider';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import currencyFormater from 'currency.js';
import { Currency } from '../utils/generated';

interface Props {
    amount: number;
}

interface CurrencyFormatProps {
    amount: number;
    currency: Currency;
}

const convertCents = (value: number) => currencyFormater(value, { fromCents: true, precision: 2 }).value;

export function FormattedCurrencyNumber({ amount, currency }: CurrencyFormatProps) {
    return (
        <FormattedNumber
            currency={currency}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            value={convertCents(amount)}
        />
    );
}

interface CurrencyFormatOptionalCurrencyProps {
    amount: number;
    currency?: Currency;
}

export function FormattedOptionalCurrency({ amount, currency }: CurrencyFormatOptionalCurrencyProps) {
    return (
        <FormattedNumber
            currency={currency}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            value={convertCents(amount)}
        />
    );
}

FormattedOptionalCurrency.defaultProps = {
    currency: undefined,
};

function CurrencyFormat({ amount }: Props) {
    const { state } = useStateData();
    return <FormattedCurrencyNumber amount={amount} currency={state?.order?.currency ?? Currency.Gbp} />;
}

export default CurrencyFormat;
