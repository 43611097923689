const Button = {
    baseStyle: {
        fontWeight: 'bold',
        borderRadius: '99999999px',
    },
    variants: {
        primary: {
            bg: '#3063E9',
            color: 'white',
        },
        link: {
            color: '#3063E9',
        },
        secondary: {
            bg: 'white',
            color: '#3063E9',
            border: '1px solid #ccc',
        },
        outline: {
            bg: 'white',
            color: 'black.600',
            border: '1.5px solid #ffffff',
            boxSizing: 'border-box',
            borderRadius: '100px',
        },
        applePay: {
            '-webkit-appearance': '-apple-pay-button!important',
            '-apple-pay-button-type': 'check-out',
            '-apple-pay-button-style': 'black',
            borderRadius: '5px',
        },
    },
    defaultProps: {
        variant: 'primary',
    },
};

export default Button;
