import { useState } from 'react';
import { seperateOrderByOrderType } from './Receipt.utils';
import { GetOrderForReceiptQuery } from '../../utils/generated';

// eslint-disable-next-line import/prefer-default-export
export const useOrderSummary = (order: NonNullable<GetOrderForReceiptQuery['getStoreOrder']>) => {
    const [currentOrderSummaryIndex, setCurrentOrderSummaryIndex] = useState(0);
    const summaries = seperateOrderByOrderType(order);
    const orderSummary = summaries[currentOrderSummaryIndex];

    const hasNext = currentOrderSummaryIndex < summaries.length - 1;
    const hasPrevious = currentOrderSummaryIndex > 0;

    const reference = orderSummary.customerProvidedReference ?? null;

    const nextOrderSummary = () => {
        setCurrentOrderSummaryIndex((index) => index + 1);
    };

    const previousOrderSummary = () => {
        setCurrentOrderSummaryIndex((index) => index - 1);
    };

    return {
        orderSummary,
        internalReference: orderSummary.collectionReference,
        receiptHeader: order.receiptConfiguration?.headerText, // todo headers and footers
        additionalReceiptText: order.receiptConfiguration?.footerText,
        reference,
        hasNext,
        hasPrevious,
        nextOrderSummary,
        previousOrderSummary,
    };
};
