import React, { createContext, useState } from 'react';
import { createIntl, createIntlCache, IntlShape, RawIntlProvider } from 'react-intl';

export enum Locales {
    'Deutsch' = 'de-DE',
    'Française' = 'fr-FR',
    'English-UK' = 'en-GB',
    'English-US' = 'en-US',
}

interface Props {
    children: React.ReactChild;
}

export const LanguageUpdateContext = createContext<{ updateLocale: (locale: Locales) => void }>({
    updateLocale: () => null,
});

function LocaleProvider({ children }: Props) {
    const [intl, setIntl] = useState<IntlShape>(() =>
        createIntl({
            locale: Locales['English-UK'],
            messages: {},
        })
    );

    const cache = createIntlCache();

    const updateLocale = (locale: Locales) => {
        const newIntl = createIntl(
            {
                locale: locale as string,
                messages: {},
            },
            cache
        );
        setIntl(newIntl);
    };

    return (
        <LanguageUpdateContext.Provider
            // disabling for next line as detection is wrong - setIntl is a setState wrapped from the lib
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                updateLocale,
            }}
        >
            <RawIntlProvider value={intl}>{children}</RawIntlProvider>
        </LanguageUpdateContext.Provider>
    );
}

export default LocaleProvider;
