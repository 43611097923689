import React, { useCallback, useEffect, useRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { useStateData } from 'providers/AppStateProvider';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { StripePaymentIntent, usePaymentSetupMutation, MutationPutPaymentSetupArgs } from 'utils/generated';
import LoadingSpinner from 'components/LoadingSpinner';
import { Stripe } from '@stripe/stripe-js';
import { OgPaymentInput } from 'typings/AppStateProvider';
import useReshapedPayment from 'helpers/useReshapedPayment';
import { captureBreadcrumb, mutationBreadcrumb, dataBreadcrumb } from 'helpers/sentryLogger';

export interface PayOutletContext {
    stripePaymentIntent: StripePaymentIntent;
    isLoading: boolean;
}

interface Props {
    stripePromise: Promise<Stripe | null>;
}

function Pay({ stripePromise }: Props) {
    const { state } = useStateData();
    const stripePaymentIntent = useRef<StripePaymentIntent | null | undefined>();
    const { merchantId, locationId, orderId } = useParams();

    const navigate = useNavigate();
    const orderUrl = `/merchant/${merchantId}/location/${locationId}/order/${orderId}`;

    const { reshape } = useReshapedPayment();

    const {
        isLoading,
        mutate: updateStripePaymentIntent,
        error,
    } = usePaymentSetupMutation({
        onSuccess: (res) => {
            captureBreadcrumb(
                dataBreadcrumb('paymentIntent.update', {
                    oldIntentId: stripePaymentIntent.current?.id,
                    newIntentId: res.putPaymentSetup!.stripePaymentIntent!.id,
                })
            );
            stripePaymentIntent.current = res.putPaymentSetup.stripePaymentIntent;
        },
        onMutate: ({ paymentProcessorData }) => {
            captureBreadcrumb(
                mutationBreadcrumb({
                    mutationName: 'putPaymentSetup',
                    intentSent: paymentProcessorData?.stripePaymentIntentId,
                })
            );
        },
    });

    if (error) {
        if (Array.isArray(error)) {
            error.forEach((oapError) => {
                if (oapError.errorType === 'PAYMENT.STATE_INVALID') {
                    navigate(`/error${orderUrl}/payment-state-invalid`, { state: { message: oapError.message } });
                } else {
                    navigate(orderUrl, { replace: true });
                }
            });
            // adding this as currently if there is a network error when updating the tip then you dont see anything.
        } else {
            navigate(`/error${orderUrl}/cant-load-network`);
        }
    }

    const updateIntent = useCallback(
        (updatedConfig: MutationPutPaymentSetupArgs) => updateStripePaymentIntent(updatedConfig),
        [updateStripePaymentIntent]
    );

    useEffect(() => {
        if (state?.payment) {
            updateIntent(reshape(state.payment as OgPaymentInput, stripePaymentIntent.current?.id));
        }
    }, [reshape, state?.payment, updateIntent]);

    if (!stripePaymentIntent.current?.id) return <LoadingSpinner />;

    return (
        <Elements stripe={stripePromise} options={{ clientSecret: stripePaymentIntent?.current?.clientSecret }}>
            <Outlet context={{ stripePaymentIntent: stripePaymentIntent.current, isLoading } as PayOutletContext} />
        </Elements>
    );
}

export default Pay;
