import React from 'react';
import { Box, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';

export enum AlertStatus {
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
    Success = 'success',
}

interface Props {
    title?: string;
    status?: AlertStatus;
    message?: string;
}

function ErrorAlert({ title, message, status }: Props) {
    return (
        <Alert status={status} border="2px solid rgba(4, 7, 21, 0.05)" borderRadius="12px">
            <AlertIcon w="6" />
            <Box flex="1">
                {title && <AlertTitle>{title}</AlertTitle>}
                <AlertDescription display="block">{message}</AlertDescription>
            </Box>
        </Alert>
    );
}

export default ErrorAlert;

ErrorAlert.defaultProps = {
    title: undefined,
    message: 'An error occured',
    status: AlertStatus.Error,
};
