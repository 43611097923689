import { GetOrderForReceiptQuery } from '../../utils/generated';

export type SummaryForOrderType = {
    lineItems: NonNullable<GetOrderForReceiptQuery['getStoreOrder']>['lineItems'];
    orderTypeId: string;
    orderTypeName: string;
    collectionReference: string;
    customerProvidedReference: string;
    amount: number;
    discount: number;
};

// eslint-disable-next-line import/prefer-default-export
export const seperateOrderByOrderType = (
    order: NonNullable<GetOrderForReceiptQuery['getStoreOrder']>
): SummaryForOrderType[] => {
    const ordersByOrderType: Record<string, SummaryForOrderType> = {};

    order.lineItems.forEach((orderLine) => {
        const { orderTypeId } = orderLine;

        const customerOrderInfo = order.customerOrderInfo.find((info) => info.orderType.id === orderTypeId);

        const orderSummary = ordersByOrderType[orderTypeId] || {
            orderTypeId,
            orderTypeName: customerOrderInfo?.orderType.name,
            lineItems: [],
            amount: 0,
            discount: 0,
            collectionReference: order.orderRef,
            customerProvidedReference: customerOrderInfo?.customerProvidedReference[0]?.value ?? null,
        };

        orderSummary.lineItems.push(orderLine);
        orderSummary.amount += orderLine.amount * orderLine.quantity;

        ordersByOrderType[orderTypeId] = orderSummary;
    });

    return Object.values(ordersByOrderType);
};
