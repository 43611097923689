import React, { FormEvent, useCallback, useState } from 'react';
import { useStripe, PaymentElement, useElements } from '@stripe/react-stripe-js';
import { useSetState, useStateData } from 'providers/AppStateProvider';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Button, Spinner } from '@chakra-ui/react';
import Page from 'components/Page';
import CurrencyFormat from 'components/CurrencyFormat';
import LoadingSpinner from 'components/LoadingSpinner';
import { PayOutletContext } from 'pages/pay/Pay';
import { OgPaymentInput } from 'typings/AppStateProvider';
import { PaymentIntent, StripeError } from '@stripe/stripe-js';
import { captureBreadcrumb, captureException, stripeBreadcrumb } from 'helpers/sentryLogger';
import StripePaymentError from 'helpers/StripePaymentError';
import { errorToast } from 'helpers/ToastHelper';

function StripePayByCardForm() {
    const { state } = useStateData();
    const { stripePaymentIntent, isLoading: intentLoading } = useOutletContext<PayOutletContext>();
    const { amount } = state?.payment as OgPaymentInput;
    const { setPaymentAuthorised } = useSetState();
    const [paymentSubmit, setPaymentSubmit] = useState(false);
    const params = useParams();

    const { merchantId, locationId, orderId } = params;

    const orderUrl = `/merchant/${merchantId}/location/${locationId}/order/${orderId}`;

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const setLoading = useCallback((value: boolean) => setPaymentSubmit(value), []);

    if (!stripePaymentIntent) navigate(-1);

    if (intentLoading || !state || !stripe || !elements) return <LoadingSpinner />;

    const submitToStripe = async () => {
        let paymentIntent: PaymentIntent | undefined;
        let error: StripeError | undefined;
        try {
            const response = await stripe.confirmPayment({
                elements,
                redirect: 'if_required',
            });
            captureBreadcrumb(
                stripeBreadcrumb({
                    stripeMethodName: 'confirmPayment',
                })
            );
            paymentIntent = response?.paymentIntent;
            error = response?.error;
        } catch (caughtError) {
            captureException(caughtError);
            return;
        }
        try {
            if (error) {
                const { message: description } = new StripePaymentError(error, state.merchant?.countryCode, params);
                errorToast({ description });
                setLoading(false);
                return;
            }
            if (paymentIntent?.status === 'requires_capture') {
                setPaymentAuthorised();
                navigate(`${orderUrl}/${stripePaymentIntent.id}/processing`, { replace: true });
            } else {
                const captureError = new Error('Unexpected paymentIntent state');
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                captureError.paymentInfo = state?.payment;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                captureError.paymentIntent = paymentIntent;
                captureException(captureError, { paymentIntentStatus: paymentIntent?.status });
                setLoading(false);
            }
        } catch (caughtError) {
            // eslint-disable-next-line no-console
            console.error(caughtError);
            captureException(caughtError);
        }
    };

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        submitToStripe();
    };

    return (
        <Page
            title="Payment Information"
            showBack
            footer={
                <Button
                    isFullWidth
                    data-cy="payNow"
                    type="submit"
                    disabled={paymentSubmit || !stripe}
                    form="payment-form"
                    id="submit"
                >
                    <span id="button-text">
                        {paymentSubmit || !stripe ? (
                            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" />
                        ) : (
                            <>
                                Pay &nbsp;
                                <CurrencyFormat amount={amount} />
                            </>
                        )}
                    </span>
                </Button>
            }
        >
            <form style={{ width: '100%' }} id="payment-form" onSubmit={submit}>
                <PaymentElement
                    onReady={(x) => {
                        const y = x as unknown as {
                            _parent: {
                                clientHeight: number;
                            };
                        };
                        setTimeout(() => {
                            // eslint-disable-next-line no-underscore-dangle
                            if (y._parent.clientHeight === 2) {
                                console.log('Stripe: Manually adjusting height.');
                                document.querySelector<HTMLIFrameElement>('.StripeElement iframe')!.style.height =
                                    '100%';
                            }
                        }, 100);
                    }}
                />
            </form>
        </Page>
    );
}

export default StripePayByCardForm;
