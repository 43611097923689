import { Navigate, useParams } from 'react-router-dom';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useGetOrderAndLocationQuery, GetOrderAndLocationQuery } from 'utils/generated';
import LoadingOrder from '../../components/LoadingOrder';

interface Props {
    children: React.ReactNode;
}

export interface OrderValue {
    order: NonNullable<GetOrderAndLocationQuery['getOrder']>;
    location: NonNullable<GetOrderAndLocationQuery['getLocationPublic']>;
    merchant: NonNullable<GetOrderAndLocationQuery['getLocationPublic']['merchant']>;
}

export type Payment = OrderValue['order']['payments'][0];

export interface OrderContextValue extends OrderValue {
    addPaymentToOrder: (paymentToAdd: Payment) => void;
}

export const OrderContext = createContext<OrderContextValue>({} as OrderContextValue);

function OrderProvider({ children }: Props) {
    const { merchantId, locationId, orderId } = useParams() as Record<string, string>;

    const { data, isLoading } = useGetOrderAndLocationQuery({
        merchantId,
        locationId,
        orderId,
    });

    const [value, setValue] = useState<OrderValue | null>(null);

    useEffect(() => {
        if (!isLoading && data?.getOrder && data?.getLocationPublic && data?.getLocationPublic?.merchant) {
            setValue({
                order: data?.getOrder,
                location: data?.getLocationPublic,
                merchant: data?.getLocationPublic.merchant,
            });
        }
    }, [data, isLoading]);

    const contextValue = useMemo(() => {
        if (!value) {
            return null;
        }

        return {
            ...value,
            addPaymentToOrder: (paymentToAdd: Payment) => {
                const paymentExistsAlready = value.order.payments.find((payment) => payment.id === paymentToAdd.id);

                // This will only happen if they've gone back to the check status after success
                if (paymentExistsAlready) return;

                setValue({
                    ...value,
                    order: {
                        ...value.order,
                        payments: [...value.order.payments, paymentToAdd],
                    },
                });
            },
        };
    }, [value, setValue]);

    if (isLoading || contextValue == null) {
        return <LoadingOrder />;
    }

    if (!isLoading && (data?.getOrder == null || data?.getLocationPublic == null)) {
        return <Navigate to="/404" />;
    }

    // We do the typecheck earlier, so it should be safe to cast here
    return <OrderContext.Provider value={contextValue as OrderContextValue}>{children}</OrderContext.Provider>;
}

export default OrderProvider;

export function useOrderInfo() {
    return useContext(OrderContext);
}
