const colors = {
    neutral: {
        n600: '#666666',
        n400: '#CCCCCC',
    },
    blue: {
        n500: '#3063E9',
        n700: '#234BC3',
    },
};

export default colors;
