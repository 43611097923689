import React, { Button, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import Page from 'components/Page';
import ItemWithQuantitySelect from 'components/ItemWithQuantitySelect';
import { FormattedCurrencyNumber } from 'components/CurrencyFormat';
import { useRoutesNavigate } from 'hooks/useRoutesNavigate';
import { useBasket } from '../../contexts/basket-provider/BasketProvider';
import { useOrderInfo } from '../../contexts/OrderProvider';
import { BasketActions } from '../../contexts/basket-provider/BasketReducer.types';
import { useDisplayData } from '../../contexts/DisplayDataProvider';

function SelectLineItems() {
    const { order } = useOrderInfo();
    const { basket, updateBasket } = useBasket();
    const { lineItemsWithQuantities } = useDisplayData();
    const navigate = useRoutesNavigate();

    const selectedItemsTotal = basket.payment.amount;
    const serviceChargeForLineItems = basket.serviceChargeDue;

    const totalIncludingServiceCharge = selectedItemsTotal;

    const add = (posId: string) => {
        const orderItem = order.lineItems?.find((item) => item.posId === posId);
        if (!orderItem) return;
        updateBasket({
            type: BasketActions.AddItem,
            payload: { item: { posId: orderItem.posId as string, amount: orderItem.amount } },
        });
    };

    const remove = (posId: string) => {
        const orderItem = order.lineItems?.find((item) => item.posId === posId);
        if (!orderItem) return;
        updateBasket({
            type: BasketActions.RemoveItem,
            payload: { item: { posId: orderItem.posId as string, amount: orderItem.amount } },
        });
    };

    const selectAll = () => {
        if (!order.lineItems) return;

        updateBasket({
            type: BasketActions.SelectAllItems,
            payload: {
                items: order.lineItems.map((item) => ({
                    posId: item.posId as string,
                    amount: item.amount,
                    quantity: item.quantity,
                })),
            },
        });
    };

    const deselectAll = () => {
        updateBasket({
            type: BasketActions.DeselectAllItems,
        });
    };

    const isAllSelected = order.lineItems.every((item) => {
        const basketItem = basket.payment?.lineItems?.find((i) => i.posId === item.posId);

        if (!basketItem) return false;
        return basketItem.quantity === item.quantity;
    });

    return (
        <Page
            showBack
            title="Pay for my items"
            footer={
                <Button
                    disabled={selectedItemsTotal <= 0}
                    isFullWidth
                    data-cy="payForItems"
                    onClick={() => {
                        updateBasket({ type: BasketActions.RemainingOrAmount });
                        navigate('ReviewPayment');
                    }}
                >
                    Pay&nbsp;
                    <FormattedCurrencyNumber amount={totalIncludingServiceCharge} currency={order.currency} />
                </Button>
            }
            onBackClick={() => navigate('SplitSelect')}
        >
            <VStack w="full">
                <HStack px="3" w="full" alignItems="center" justify="space-between">
                    <Heading as="h2" size="sm" textAlign="center">
                        Item list
                    </Heading>
                    {!isAllSelected && (
                        <Button size="sm" variant="link" onClick={selectAll}>
                            Select all
                        </Button>
                    )}
                    {isAllSelected && (
                        <Button size="sm" variant="link" onClick={deselectAll}>
                            Clear selection
                        </Button>
                    )}
                </HStack>
                <VStack w="full" borderWidth="2px" borderRadius="2xl" p="1" spacing="1">
                    {Object.entries(lineItemsWithQuantities).map(([posId, item]) => {
                        const selectedItem = basket.payment?.lineItems?.find(
                            (itemInBasket) => itemInBasket.posId === posId
                        );

                        const selectedItemQuantity = selectedItem?.quantity ?? 0;

                        return (
                            <ItemWithQuantitySelect
                                key={posId}
                                itemId={posId}
                                itemProps={item}
                                quantity={selectedItemQuantity}
                                add={add}
                                remove={remove}
                                currency={order.currency}
                            />
                        );
                    })}
                    {order.serviceCharge && (
                        <VStack p="2" w="full">
                            <Divider />
                            <HStack w="full" alignItems="center" justify="space-between">
                                <Text fontWeight="500" fontSize="sm">
                                    Service charge for items
                                </Text>
                                <Text fontWeight="700" fontSize="sm">
                                    <FormattedCurrencyNumber
                                        amount={serviceChargeForLineItems}
                                        currency={order.currency}
                                    />
                                </Text>
                            </HStack>
                        </VStack>
                    )}
                </VStack>
            </VStack>
        </Page>
    );
}

export default SelectLineItems;
