export const Routes: Record<string, string> = {
    Overview: '/op/merchant/:merchantId/location/:locationId/order/:orderId/',
    SplitSelect: '/op/merchant/:merchantId/location/:locationId/order/:orderId/split-select',
    SelectMyItems: '/op/merchant/:merchantId/location/:locationId/order/:orderId/select-my-items',
    SelectTotalGuests: '/op/merchant/:merchantId/location/:locationId/order/:orderId/select-total-guests',
    SelectGuestsToPayFor: '/op/merchant/:merchantId/location/:locationId/order/:orderId/select-guests-to-pay-for',
    PaymentSuccess: '/op/merchant/:merchantId/location/:locationId/order/:orderId/success',
    ReviewPayment: '/op/merchant/:merchantId/location/:locationId/order/:orderId/review-payment',
    Payment: '/op/merchant/:merchantId/location/:locationId/order/:orderId/pay/:paymentId',
    PaymentStatus: '/op/merchant/:merchantId/location/:locationId/order/:orderId/status/:paymentId',
    Error: '/error',
};

export type RouteKey = keyof typeof Routes;
