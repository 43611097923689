import React, { useEffect } from 'react';
import Page from 'components/Page';
import { VStack, Text, Image, Button, Heading, HStack, IconButton } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { useRoutesNavigate } from 'hooks/useRoutesNavigate';
import { useBasket } from '../../contexts/basket-provider/BasketProvider';
import { BasketActions } from '../../contexts/basket-provider/BasketReducer.types';
import { FormattedCurrencyNumber } from '../../../components/CurrencyFormat';
import { OrderPaymentType } from '../../../utils/generated';

function SelectTotalGuests() {
    const navigate = useRoutesNavigate();

    const { basket, updateBasket } = useBasket();

    const partySize = basket.payment.orderPaymentIntention.partySize ?? 1;

    useEffect(() => {
        if (basket.payment.orderPaymentIntention.orderPaymentType !== OrderPaymentType.Party) {
            updateBasket({ type: BasketActions.SetupPartyPayment });
        }
    }, [basket.payment.orderPaymentIntention.orderPaymentType, updateBasket]);

    function incrementPartySize() {
        updateBasket({ type: BasketActions.IncreasePeople });
    }

    function decrementPartySize() {
        updateBasket({ type: BasketActions.DecreasePeople });
    }

    const totalDueForParty = Math.ceil(basket.orderTotal / partySize);

    return (
        <Page
            showBack
            title="Guest number"
            footer={
                <Button
                    isFullWidth
                    data-cy="payAll"
                    onClick={() => navigate('SelectGuestsToPayFor')}
                    disabled={totalDueForParty <= 0}
                >
                    Continue
                </Button>
            }
            onBackClick={() => navigate('SplitSelect')}
        >
            <VStack>
                {partySize >= 1 && <Image mt="16" h="44" src={`/img/group-${partySize <= 5 ? partySize : 5}.svg`} />}
                <Heading pt="5" as="h2" size="md" textAlign="center">
                    Select the number of guests
                </Heading>
                <HStack align="center" spacing="8">
                    <IconButton
                        variant="secondary"
                        aria-label="Remove Guest"
                        icon={<MinusIcon />}
                        color="black"
                        disabled={partySize === 1}
                        onClick={() => decrementPartySize()}
                    />
                    <Text fontSize="5xl" as="strong">
                        {partySize}
                    </Text>
                    <IconButton
                        variant="secondary"
                        aria-label="Add Guest"
                        icon={<AddIcon />}
                        color="black"
                        onClick={() => incrementPartySize()}
                    />
                </HStack>
                <VStack mt="-3" spacing="-0.5">
                    <Text fontWeight="bold">
                        <FormattedCurrencyNumber amount={totalDueForParty} currency={basket.payment.currency} />
                    </Text>
                    <Text fontSize="sm">Paid by each guest</Text>
                </VStack>
            </VStack>
        </Page>
    );
}

export default SelectTotalGuests;
