import React from 'react';
import { Tr, Td } from '@chakra-ui/react';
import CurrencyFormat, { FormattedOptionalCurrency } from 'components/CurrencyFormat';
import { Currency } from 'utils/generated';

interface Props {
    title: string;
    amount: number;
    // eslint-disable-next-line react/require-default-props
    strong?: boolean;
    // eslint-disable-next-line react/require-default-props
    currency?: Currency;
}

function TitleAmountRow({ title, amount, currency, strong = false }: Props) {
    return (
        <Tr fontWeight={(strong && 700) || 400}>
            <Td border="none">{title}</Td>
            <Td border="none" isNumeric>
                {currency ? (
                    <FormattedOptionalCurrency amount={amount} currency={currency} />
                ) : (
                    <CurrencyFormat amount={amount} />
                )}
            </Td>
        </Tr>
    );
}

export default TitleAmountRow;
