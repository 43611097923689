import { FulfilmentDetail, GetOapOrderForReceiptQuery, PriceBook } from '../../utils/generated';

export type OrderSummaryByFulfilmentType = {
    priceBook: PriceBook;
    lineItems: NonNullable<GetOapOrderForReceiptQuery['getCompletedOrder']>['lineItems'];
    fulfilmentDetail: FulfilmentDetail;
    amount: number;
    discount: number;
};

// eslint-disable-next-line import/prefer-default-export
export const separateOrderByFulfilmentDetails = (
    order: NonNullable<GetOapOrderForReceiptQuery['getCompletedOrder']>
): OrderSummaryByFulfilmentType[] => {
    const ordersByFulfilmentType: Record<string, OrderSummaryByFulfilmentType> = {};

    order.lineItems.forEach((orderLine) => {
        const priceBook = orderLine.priceBookLine?.priceBook ?? { id: 'UNKNOWN', name: 'Unknown' };

        const fulfilmentDetailId = orderLine.fulfilmentDetailId ?? 'UNKNOWN';
        const matchedFulfilmentDetail = order.fulfilmentDetails.find((detail) => detail.id === fulfilmentDetailId) ?? {
            fulfilmentType: 'UNKNOWN',
            reference: null,
            id: 'UNKNOWN',
        };

        const orderSummary = ordersByFulfilmentType[fulfilmentDetailId] || {
            priceBook,
            lineItems: [],
            amount: 0,
            fulfilmentDetail: matchedFulfilmentDetail,
            discount: 0,
        };

        const lineDiscount = orderLine.amountDiscountTotal ?? 0;

        orderSummary.lineItems.push(orderLine);
        orderSummary.amount += orderLine.amount * orderLine.quantity - lineDiscount;
        orderSummary.discount += lineDiscount;
        ordersByFulfilmentType[fulfilmentDetailId] = orderSummary;
    });

    return Object.values(ordersByFulfilmentType);
};
