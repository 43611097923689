import React, { useEffect, useMemo, useState } from 'react';
import { Button, Heading, HStack, VStack, Text, Divider } from '@chakra-ui/react';
import Page from 'components/Page';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetState, useStateData } from 'providers/AppStateProvider';
import ItemWithQuantitySelect from 'components/ItemWithQuantitySelect';
import { OrderV0, OrderPaymentType, ServicePaymentType } from 'utils/generated';
import CurrencyFormat from 'components/CurrencyFormat';
import LoadingSpinner from 'components/LoadingSpinner';
import OrderHelper from 'helpers/OrderHelper';
import { SplitItems } from 'typings/splitItem';

type selectedItems = { posId: string; quantity: number; amount: number }[] | undefined;

function SelectMyItems() {
    const navigate = useNavigate();
    const [items, setItems] = useState<SplitItems>();
    const [selected, setSelected] = useState<selectedItems>();
    const { merchantId, locationId, orderId } = useParams();
    const { state } = useStateData();
    const { setPayment } = useSetState();

    const orderHelper = useMemo(() => new OrderHelper(state!.order as OrderV0), [state]);

    useEffect(() => {
        if (!items) {
            if (state?.payment?.lineItems) setSelected(state.payment.lineItems);
            setItems(orderHelper.lineItemsDetails());
        }
    }, [items, orderHelper, state]);

    const selectedItemsTotal = () =>
        selected?.reduce((acc, selectedItem) => selectedItem.amount * selectedItem.quantity + acc, 0) ?? 0;

    const selectionAllOrClear = () => {
        if (selected) setSelected(undefined);
        if ((!selected || selected.length === 0) && items)
            setSelected(
                Object.keys(items).map((itemid) => ({
                    posId: itemid,
                    amount: items[itemid].singleItemAmount,
                    quantity: items[itemid].remainingQuantity,
                }))
            );
    };

    if (!items) return <LoadingSpinner />;

    const add = (posId: string) => {
        const selectedItemIndex = selected?.find((item) => item.posId === posId);

        if (selectedItemIndex) {
            setSelected(
                selected?.map((item) =>
                    item.posId === posId
                        ? {
                              posId,
                              quantity: item.quantity + 1,
                              amount: items[posId].singleItemAmount,
                          }
                        : item
                )
            );
        } else {
            setSelected([{ posId, quantity: 1, amount: items[posId].singleItemAmount }].concat(selected ?? []));
        }
    };

    const remove = (posId: string) => {
        const currentSelectionQuantity = selected?.find((item) => item.posId === posId)?.quantity;

        if (!currentSelectionQuantity) throw new Error('Item not found in selection array');

        setSelected(
            selected?.map((item) =>
                item.posId === posId
                    ? {
                          ...item,
                          quantity: item.quantity - 1,
                      }
                    : item
            )
        );
    };

    const handlePayClick = () => {
        const lineItems = selected?.filter((item) => item.quantity > 0);
        const amount = orderHelper.serviceChargeDue(selectedItemsTotal());
        const servicePayment = amount
            ? {
                  amount,
                  type: ServicePaymentType.ServiceCharge,
                  description: 'Service charge apportioned by item',
              }
            : undefined;
        setPayment({
            lineItems,
            servicePayment,
            amount: selectedItemsTotal() + orderHelper.serviceChargeDue(selectedItemsTotal()),
            orderPaymentIntention: { orderPaymentType: OrderPaymentType.Item },
        });
        navigate(`/merchant/${merchantId}/location/${locationId}/order/${orderId}/pay`);
    };

    return (
        <Page
            showBack
            backUrl={
                orderHelper.orderHasItemPayment()
                    ? `/merchant/${merchantId}/location/${locationId}/order/${orderId}`
                    : undefined
            }
            title="Pay for my items"
            footer={
                <Button disabled={selectedItemsTotal() < 30} isFullWidth data-cy="payForItems" onClick={handlePayClick}>
                    Pay&nbsp;
                    <CurrencyFormat
                        amount={selectedItemsTotal() + orderHelper.serviceChargeDue(selectedItemsTotal())}
                    />
                </Button>
            }
        >
            <VStack w="full">
                <HStack px="3" w="full" alignItems="center" justify="space-between">
                    <Heading as="h2" size="sm" textAlign="center">
                        Item list
                    </Heading>
                    <Button size="sm" variant="link" onClick={selectionAllOrClear}>
                        {selected && selected.length >= 1 ? 'Clear selection' : 'Select all'}
                    </Button>
                </HStack>
                <VStack w="full" borderWidth="2px" borderRadius="2xl" p="1" spacing="1">
                    {items &&
                        state?.order?.lineItems
                            .filter((item) => items[item.posId!].remainingQuantity > 0)
                            .map((item) => (
                                <ItemWithQuantitySelect
                                    key={item.posId}
                                    itemId={item.posId!}
                                    itemProps={items[item.posId!]}
                                    quantity={
                                        selected?.find((selectedItem) => selectedItem.posId === item.posId)?.quantity ??
                                        0
                                    }
                                    add={add}
                                    remove={remove}
                                    currency={state.order!.currency}
                                />
                            ))}
                    {state?.order?.serviceCharge && (
                        <VStack p="2" w="full">
                            <Divider />
                            <HStack w="full" alignItems="center" justify="space-between">
                                <Text fontWeight="500" fontSize="sm">
                                    Service charge for items
                                </Text>
                                <Text fontWeight="700" fontSize="sm">
                                    <CurrencyFormat amount={orderHelper.serviceChargeDue(selectedItemsTotal())} />
                                </Text>
                            </HStack>
                        </VStack>
                    )}
                </VStack>
            </VStack>
        </Page>
    );
}

export default SelectMyItems;
