import React from 'react';
import { Stack } from '@chakra-ui/react';
import { Player } from '@lottiefiles/react-lottie-player';

function LoadingReceipt() {
    return (
        <Stack h="70vh" align="center" justify="center">
            <Player
                autoplay
                controls={false}
                style={{ height: '300px', width: '300px' }}
                loop
                src="/animations/oapLoadingAnimation.json"
            />
        </Stack>
    );
}

export default LoadingReceipt;
