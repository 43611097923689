import { MutationStartPaymentArgs } from '../../../utils/generated';

export enum BasketActions {
    ClearBasket = 'CLEAR_BASKET',
    SetupFullPayment = 'SETUP_FULL_PAYMENT',
    SetupItemPayment = 'SETUP_ITEM_PAYMENT',
    SetupPartyPayment = 'SETUP_PARTY_PAYMENT',
    AddTip = 'ADD_TIP',
    RemoveTip = 'REMOVE_TIP',
    AddPartyMember = 'ADD_PARTY_MEMBER',
    RemovePartyMember = 'REMOVE_PARTY_MEMBER',
    IncreasePeople = 'INCREASE_PEOPLE',
    DecreasePeople = 'DECREASE_PEOPLE',
    AddItem = 'ADD_ITEM',
    RemoveItem = 'REMOVE_ITEM',
    SelectAllItems = 'SELECT_ALL_ITEMS',
    DeselectAllItems = 'DESELECT_ALL_ITEMS',
    RemainingOrAmount = 'REMAINING_OR_TOTAL',
}

export type BasketUpdates =
    | { type: BasketActions.ClearBasket }
    | { type: BasketActions.SetupItemPayment }
    | { type: BasketActions.SetupPartyPayment }
    | { type: BasketActions.AddTip; payload: { tipPercentage: number } }
    | { type: BasketActions.RemoveTip }
    | { type: BasketActions.AddPartyMember }
    | { type: BasketActions.RemovePartyMember }
    | { type: BasketActions.IncreasePeople }
    | { type: BasketActions.DecreasePeople }
    | { type: BasketActions.AddItem; payload: { item: { posId: string; amount: number } } }
    | { type: BasketActions.RemoveItem; payload: { item: { posId: string; amount: number } } }
    | {
          type: BasketActions.SelectAllItems;
          payload: { items: Array<{ posId: string; amount: number; quantity: number }> };
      }
    | { type: BasketActions.DeselectAllItems }
    | { type: BasketActions.SetupFullPayment; payload: { amount: number } }
    | { type: BasketActions.RemainingOrAmount };

export interface BasketState extends Omit<MutationStartPaymentArgs, 'redirectUrl'> {
    serviceChargeEnabled: boolean;
    totalServiceCharge: number;
    serviceChargeDue: number;
    orderTotal: number;
    totalRemaining: number;
    hasTotalBeenLowered: boolean;
}
