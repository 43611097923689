import React from 'react';
import Page from 'components/Page';
import { VStack, Text, Image, Flex } from '@chakra-ui/react';
import Selector from 'components/Selector';
import { useRoutesNavigate } from 'hooks/useRoutesNavigate';
import { useBasket } from '../../contexts/basket-provider/BasketProvider';
import { BasketActions } from '../../contexts/basket-provider/BasketReducer.types';

function SplitSelectPage() {
    const splitByItemAvailable = process.env.REACT_APP_SPLIT_BY_ITEM_DISABLED !== 'true';
    const { updateBasket } = useBasket();
    const navigate = useRoutesNavigate();

    const handleClickItemSplit = () => {
        updateBasket({ type: BasketActions.SetupItemPayment });
        navigate('SelectMyItems');
    };

    const handleClickPartySplit = () => {
        updateBasket({ type: BasketActions.SetupPartyPayment });
        navigate('SelectTotalGuests');
    };

    return (
        <Page showBack title="Split the bill" onBackClick={() => navigate('Overview')}>
            <Flex flexDirection="column" h="full" flexGrow="1" justify="center">
                <Image h="44" src="/img/split-the-bill.svg" />
                <Text pt="9" pb="0" textAlign="center" fontSize="2xl" fontWeight="bold" as="h1">
                    How do you want to split?
                </Text>
                <Text pb="8" textAlign="center">
                    Choose your preferred method
                </Text>
                <VStack w="full" spacing="4">
                    {splitByItemAvailable && (
                        <Selector onClick={handleClickItemSplit}>
                            <Text p="0" textAlign="left" fontWeight="bold">
                                Pay for your items.
                            </Text>
                            <Text p="0" textAlign="left" fontSize="sm">
                                Guests select the items to pay
                            </Text>
                        </Selector>
                    )}
                    <Selector onClick={handleClickPartySplit}>
                        <Text fontWeight="bold">Pay in equal parts.</Text>
                        <Text fontSize="sm">Guests pay the same amount</Text>
                    </Selector>
                </VStack>
            </Flex>
        </Page>
    );
}

export default SplitSelectPage;
