import { useResolvedPath } from 'react-router-dom';
import { Button, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { HiLightningBolt } from 'react-icons/hi';
import React, { useEffect } from 'react';
import { useDisplayData } from 'pages/contexts/DisplayDataProvider';
import Page from '../../../components/Page';
import { FormattedCurrencyNumber } from '../../../components/CurrencyFormat';
import ShareButton from '../../../components/ShareButton';
import { useOrderInfo } from '../../contexts/OrderProvider';
import { useFetchOrderPaymentsQuery } from '../../../utils/generated';

function PaymentSuccessOnlinePayments() {
    const pathToIndex = useResolvedPath('../');
    const pathToSplitSelect = useResolvedPath('../split-select');

    const { totalDue } = useDisplayData();
    const { order, merchant, addPaymentToOrder } = useOrderInfo();

    const handleViewReceiptClick = () => {
        window.location.href = `${window.location.origin}${pathToIndex.pathname}`;
    };

    const totalRemainingToPay = totalDue;
    const nothingLeftToPay = totalRemainingToPay <= 0;

    // 10 seconds
    const refetchIntervalInMiliseconds = 10000;

    const { data, isLoading } = useFetchOrderPaymentsQuery(
        {
            merchantId: merchant.id,
            orderId: order.id,
        },
        {
            refetchInterval: refetchIntervalInMiliseconds,
        }
    );

    useEffect(() => {
        if (!isLoading && data) {
            data.getOrder?.payments.forEach((payment) => {
                addPaymentToOrder(payment);
            });
        }
    }, [addPaymentToOrder, data, isLoading]);

    return (
        <Page
            footer={
                <Text mb="4" align="center">
                    Powered by SumUp
                </Text>
            }
        >
            <VStack>
                <Image mt="16" mb="8" width="200px" src="/img/success-graphic.svg" />

                <Heading p="2" size="md" as="h1">
                    {nothingLeftToPay ? 'Your bill is paid!' : 'Payment confirmed'}
                </Heading>

                {nothingLeftToPay && (
                    <Text pb="8" align="center" fontSize="md">
                        Thank you! The servers know you&apos;ve paid, feel free to leave whenever you&apos;re ready
                    </Text>
                )}
                {!nothingLeftToPay && (
                    <VStack pb="5" spacing="0">
                        <Text>Your payment is complete</Text>
                        <HStack pb="4">
                            <Text align="center" fontSize="md">
                                Your table still has&nbsp;
                                <strong>
                                    <FormattedCurrencyNumber amount={totalRemainingToPay} currency={order.currency} />
                                </strong>
                                &nbsp;to pay
                            </Text>
                        </HStack>
                        <ShareButton
                            url={`${window.location.origin}${pathToSplitSelect.pathname}`}
                            nameOfSharedItem="payment link"
                        />
                    </VStack>
                )}
                <Button
                    data-cy="viewReceipt"
                    variant="outline"
                    leftIcon={<HiLightningBolt />}
                    onClick={handleViewReceiptClick}
                >
                    View receipt
                </Button>
            </VStack>
        </Page>
    );
}

export default PaymentSuccessOnlinePayments;
