import { Flex } from '@chakra-ui/react';
import React from 'react';

function NotFound() {
    return (
        <Flex h="100vh" align="center" justify="center">
            404 Not Found
        </Flex>
    );
}

export default NotFound;
