import { Params } from 'react-router-dom';
import { Breadcrumb, addBreadcrumb, captureException as sentryCaptureException } from '@sentry/react';
import { StripeError } from '@stripe/stripe-js';

export function captureException(
    error: Error | StripeError | unknown,
    tags?: { [x: string]: string | undefined },
    params?: Readonly<Params<string>>
) {
    const deviceId = localStorage.getItem('device-id');

    sentryCaptureException(error, {
        tags: {
            merchant_id: params?.merchantId,
            order_id: params?.orderId,
            location_id: params?.locationId,
            device_id: deviceId ?? undefined,
            ...tags,
        },
    });
}

export function mutationBreadcrumb(data: { [key: string]: unknown }) {
    return {
        category: 'mutation',
        data,
        type: 'http',
    };
}

export function dataBreadcrumb(category: string, data: { [key: string]: unknown }) {
    return {
        category,
        data,
        type: 'default',
    };
}

export function stripeBreadcrumb(data: { [key: string]: unknown }) {
    return {
        category: 'stripe',
        data,
        type: 'http',
    };
}

export function captureBreadcrumb(breadCrumbInfo: Breadcrumb) {
    addBreadcrumb(breadCrumbInfo);
}
