import React, { createContext, useContext, useMemo } from 'react';
import { useBasketReducer } from './BasketReducer';
import { Currency, OrderPaymentType } from '../../../utils/generated';
import { useOrderInfo } from '../OrderProvider';
import { BasketState, BasketUpdates } from './BasketReducer.types';
import { useDisplayData } from '../DisplayDataProvider';

interface Props {
    children: React.ReactNode;
}

export const initialState = ({
    orderId,
    merchantId,
    currency,
    serviceChargeAmount,
    serviceChargeEnabled,
    orderTotal,
    totalRemaining,
}: {
    orderId: string;
    merchantId: string;
    currency: Currency;
    serviceChargeAmount: number;
    serviceChargeEnabled: boolean;
    orderTotal: number;
    totalRemaining: number;
}) => ({
    merchantId,
    orderId,
    payment: {
        amount: 0,
        currency,
        orderPaymentIntention: {
            orderPaymentType: OrderPaymentType.Full,
        },
        consumerName: undefined,
        lineItems: [],
        servicePayment: undefined,
    },
    totalServiceCharge: serviceChargeAmount,
    serviceChargeEnabled,
    serviceChargeDue: 0,
    orderTotal,
    totalRemaining,
    hasTotalBeenLowered: false,
});

interface BasketContext {
    basket: BasketState;
    updateBasket: React.Dispatch<BasketUpdates>;
}

const BasketContext = createContext<BasketContext>({} as BasketContext);

function BasketProvider({ children }: Props) {
    const { merchant, order } = useOrderInfo();
    // Possibly merge display data with the basket reducer
    const { totalDue } = useDisplayData();

    const [basket, updateBasket] = useBasketReducer(
        initialState({
            merchantId: merchant.id,
            orderId: order.id,
            currency: order.currency,
            serviceChargeAmount: order.serviceCharge?.amount ?? 0,
            serviceChargeEnabled: Boolean(order.serviceCharge),
            orderTotal: order.amount,
            totalRemaining: totalDue,
        })
    );
    const value = useMemo(
        () => ({
            basket,
            updateBasket,
        }),
        [basket, updateBasket]
    );
    return <BasketContext.Provider value={value}>{children}</BasketContext.Provider>;
}

export const useBasket = (): BasketContext => {
    return useContext(BasketContext);
};

export default BasketProvider;
