import { generatePath, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useOrderInfo } from '../pages/contexts/OrderProvider';
import { RouteKey, Routes } from '../pages/op/constants/routes';

// eslint-disable-next-line import/prefer-default-export
export const useRoutesNavigate = () => {
    const { order, merchant, location } = useOrderInfo();
    const navigate = useNavigate();

    const routesNavigate = useCallback(
        (routeKey: RouteKey, additionalParams: Record<string, string> = {}) => {
            const path = generatePath(Routes[routeKey], {
                merchantId: merchant.id,
                locationId: location.id,
                orderId: order.id,
                ...additionalParams,
            });
            navigate(path);
        },
        [merchant.id, location.id, order.id, navigate]
    );

    return routesNavigate;
};
