import React, { createContext, useCallback, useState } from 'react';
import { IAppState, IAppUpdateContext, OgPaymentInput } from 'typings/AppStateProvider';
import { Currency, Location, OrderV0, Merchant } from 'utils/generated';

interface Props {
    children: React.ReactChild;
}

const AppUpdateContext = createContext<IAppUpdateContext>({
    setOrder: () => null,
    setPayment: () => null,
    setMerchant: () => null,
    setLocation: () => null,
    setPaymentAuthorised: () => null,
});

const AppStateContext = createContext<{ state?: IAppState }>({});

function AppStateProvider({ children }: Props) {
    const [merchant, setMerchantConfig] = useState<Merchant>();
    const [location, setLocationConfig] = useState<Location>();
    const [paymentAuthorised, updatePaymentAuthorised] = useState<boolean>(false);
    const [order, setOrderConfig] = useState<OrderV0 & { currency: Currency }>();
    const [payment, setPaymentConfig] = useState<OgPaymentInput>();

    const setMerchant = useCallback((newMerchantConfig: Merchant) => {
        setMerchantConfig(newMerchantConfig);
    }, []);

    const setLocation = useCallback((newLocationConfig) => {
        setLocationConfig(newLocationConfig);
    }, []);

    const setOrder = useCallback((newOrderConfig: OrderV0 & { currency: Currency }) => {
        setOrderConfig(newOrderConfig);
    }, []);

    const setPayment = useCallback(
        (newPaymentConfig: Partial<OgPaymentInput>) => {
            setPaymentConfig({ ...payment, ...newPaymentConfig } as OgPaymentInput);
        },
        [payment]
    );

    const setPaymentAuthorised = useCallback(() => {
        updatePaymentAuthorised(true);
    }, []);

    return (
        <AppUpdateContext.Provider
            // disabling for next line as detection is wrong - They are wrapped in useCallback
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                setMerchant,
                setLocation,
                setOrder,
                setPayment,
                setPaymentAuthorised,
            }}
        >
            <AppStateContext.Provider
                // disabling for next line as detection is wrong - They are wrapped in useCallback
                // eslint-disable-next-line react/jsx-no-constructed-context-values
                value={{ state: { order, payment, merchant, location, paymentAuthorised } }}
            >
                {children}
            </AppStateContext.Provider>
        </AppUpdateContext.Provider>
    );
}

export function useSetState() {
    return React.useContext(AppUpdateContext);
}

export function useStateData() {
    return React.useContext(AppStateContext);
}

export default AppStateProvider;
