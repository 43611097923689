import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import Button from './Button';
import Spinner from './Spinner';

const theme = extendTheme({
    colors,
    components: {
        Button,
        Spinner,
    },
});

export default theme;
