import { useSetState, useStateData } from 'providers/AppStateProvider';
import { useCallback } from 'react';
import { OrderV0, OrderPaymentType } from 'utils/generated';
import OrderHelper from './OrderHelper';

function useSplitInEqualParts() {
    const { state } = useStateData();
    const { setPayment } = useSetState();

    const orderHelper = new OrderHelper(state?.order as OrderV0);

    const partySize = state?.payment?.orderPaymentIntention.partySize ?? 0;
    const parties = state?.payment?.parties ?? 0;
    const lineItems = state?.payment?.lineItems;

    function calculateAmountDue() {
        if (!lineItems) {
            if (parties + orderHelper.numberOfpartyMembersPaid() === partySize) return orderHelper.totalDue();
            const membersToPay = orderHelper.numberOfpartyMembersToPay() ?? partySize;
            if (membersToPay > 0) return Math.floor((orderHelper.totalDue() / membersToPay) * parties);
            return orderHelper.totalDue();
        }
        return lineItems.reduce((acc, lineItem) => lineItem.amount * lineItem.quantity + acc, 0);
    }

    const amountDueForSelected = calculateAmountDue();

    const incrementParties = useCallback(() => {
        setPayment({ parties: parties + 1 });
    }, [parties, setPayment]);

    const decrementParties = useCallback(() => {
        setPayment({ parties: parties - 1 });
    }, [parties, setPayment]);

    const incrementPartySize = useCallback(() => {
        const orderPaymentIntention = {
            orderPaymentType: OrderPaymentType.Party,
            partySize: partySize + 1,
        };
        setPayment({ orderPaymentIntention });
    }, [partySize, setPayment]);

    const decrementPartySize = useCallback(() => {
        const orderPaymentIntention = {
            orderPaymentType: OrderPaymentType.Party,
            partySize: partySize - 1,
        };
        setPayment({ orderPaymentIntention });
    }, [partySize, setPayment]);

    const updateTotal = useCallback(() => {
        setPayment({ amount: amountDueForSelected });
    }, [amountDueForSelected, setPayment]);

    if (partySize < parties) setPayment({ parties: 1 });

    const numberOfpartyMembersPaid = orderHelper.numberOfpartyMembersPaid();
    const numberOfpartyMembersToPay = orderHelper.numberOfpartyMembersToPay() ?? partySize;
    const lastPayment = orderHelper.lastPayment();

    return {
        partySize,
        parties,
        amountDueForSelected,
        numberOfpartyMembersPaid,
        numberOfpartyMembersToPay,
        lastPayment,
        incrementParties,
        decrementParties,
        incrementPartySize,
        decrementPartySize,
        updateTotal,
    };
}

export default useSplitInEqualParts;
