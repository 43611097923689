import React from 'react';
import { Text, Flex, VStack, Button } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import Page from 'components/Page';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
    errorType?: string;
}

function ErrorPage({ errorType }: Props) {
    const locationState = (useLocation()?.state as { message?: string | undefined }) ?? undefined;
    const navigate = useNavigate();
    function errorMessage(reason?: string, retry?: boolean) {
        return (
            <VStack mt="32" spacing="16">
                <WarningIcon color="#E53E3E" h="20" w="20" />
                <Text align="center" fontSize="md">
                    Please ask your server for assistance.
                </Text>
                {reason && (
                    <Text fontWeight="light" textAlign="center">
                        {reason}
                    </Text>
                )}
                {retry && <Button onClick={() => navigate(-1)}>Retry</Button>}
            </VStack>
        );
    }

    return (
        <Page>
            <Flex justifyContent="center" alignContent="center">
                {errorType === 'POS_ORDER_UNSUPPORTED' && errorMessage(locationState?.message)}
                {errorType === 'PAYMENT.STATE_INVALID' && errorMessage(locationState?.message)}
                {errorType === 'ORDER.NOT_AVAILABLE' && errorMessage('We cannot get your order at the moment.', true)}
                {errorType === 'NETWORK_UNAVAILABLE' && errorMessage('Please check your connection.', true)}
                {!errorType && errorMessage(locationState?.message)}
            </Flex>
        </Page>
    );
}

export default ErrorPage;

ErrorPage.defaultProps = {
    errorType: undefined,
};
