import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import LandingPage from 'pages/LandingPage';
import QrLookup from 'pages/qr/QrLookup';
import OrderContainer from 'pages/order/OrderContainer';
import PaymentSelect from 'pages/pay/PaymentSelect';
import Pay from 'pages/pay/Pay';
import OnlinePayments from 'pages/op/pay/Pay';
import StripePayByCardForm from 'components/stripe/StripePayByCardForm';
import PaymentSuccess from 'pages/pay/PaymentSuccess';
import Demo, { DemoType } from 'pages/qr/Demo';
import OrderDetail from 'pages/order/OrderDetail';
import NotFound from 'pages/NotFound';
import LocationContainer from 'pages/location/LocationContainer';
import LocationMenu from 'pages/location/LocationMenu';
import MerchantContainer from 'pages/merchant/MerchantContainer';
import SplitByEqualPartContainer from 'pages/split/inEqualParts/SplitByEqualPartContainer';
import SplitEqualParts from 'pages/split/inEqualParts/SplitEqualParts';
import SelectMyPart from 'pages/split/inEqualParts/SelectMyParts';
import { loadStripe } from '@stripe/stripe-js';
import SplitByItemContainer from 'pages/split/byItem/SplitByItemContainer';
import SelectMyItems from 'pages/split/byItem/SelectMyItems';
import SplitMethodSelect from 'pages/split/SplitMethodSelect';
import ErrorPage from 'pages/ErrorPage';
import Processing from 'pages/pay/Processing';
import ReceiptQR from 'pages/qr/ReceiptQr';
import DemoReceiptQR from 'pages/qr/DemoReceiptQr';
import SelectTotalGuests from 'pages/op/select-total-guests/SelectTotalGuests';
import PaymentSuccessOnlinePayments from 'pages/op/success/PaymentSuccessOnlinePayments';
import ReviewPayment from 'pages/op/review-payment/ReviewPayment';
import OpContexts from '../pages/contexts/OpContexts';
import Overview from '../pages/op/overview/Overview';
import SplitSelectPage from '../pages/op/split-select/SplitSelectPage';
import SelectLineItems from '../pages/op/select-items/SelectLineItems';
import SelectGuestsToPayFor from '../pages/op/select-guests-to-pay-for/SelectGuestsToPayFor';
import CheckPaymentStatus from '../pages/op/CheckPaymentStatus/CheckPaymentStatus';
import V0ReceiptContainer from '../pages/v0-receipt/V0ReceiptContainer';
import ReceiptContainer from '../pages/receipt/ReceiptContainer';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

function Router() {
    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="demo" element={<Demo demoType={DemoType.Stripe} />} />
            <Route path="demo/sumup" element={<Demo demoType={DemoType.SumUp} />} />
            <Route path="demo-rqr" element={<DemoReceiptQR />} />
            <Route path="r">
                <Route path=":orderId" element={<V0ReceiptContainer />} />
            </Route>
            <Route path="r2">
                <Route path=":orderId" element={<ReceiptContainer />} />
            </Route>
            <Route path="qr">
                <Route path=":qrCode" element={<QrLookup />} />
            </Route>
            <Route path="/posOrder*" element={<ReceiptQR />} />
            <Route path="/op/merchant/:merchantId/location/:locationId/order/:orderId" element={<OpContexts />}>
                <Route index element={<Overview />} />
                <Route path="split-select" element={<SplitSelectPage />} />
                <Route path="select-my-items" element={<SelectLineItems />} />
                <Route path="select-total-guests" element={<SelectTotalGuests />} />
                <Route path="select-guests-to-pay-for" element={<SelectGuestsToPayFor />} />
                <Route path="success" element={<PaymentSuccessOnlinePayments />} />
                <Route path="review-payment" element={<ReviewPayment />} />
                <Route path="pay/:paymentId" element={<OnlinePayments />} />
                <Route path="status/:paymentId" element={<CheckPaymentStatus />} />
            </Route>
            <Route path="merchant" element={<MerchantContainer />}>
                <Route path=":merchantId">
                    <Route index element={<Navigate to="/404" />} />
                    <Route path="location" element={<LocationContainer />}>
                        <Route path=":locationId">
                            <Route index element={<Navigate to="/404" />} />
                            <Route path="menu" element={<LocationMenu />} />
                            <Route path="order" element={<OrderContainer />}>
                                <Route path=":orderId">
                                    <Route index element={<OrderDetail />} />
                                    <Route path="split">
                                        <Route index element={<SplitMethodSelect />} />
                                        <Route path="by-item" element={<SplitByItemContainer />}>
                                            <Route index element={<SelectMyItems />} />
                                        </Route>
                                        <Route path="by-equal-parts" element={<SplitByEqualPartContainer />}>
                                            <Route index element={<SplitEqualParts />} />
                                            <Route path="my-part" element={<SelectMyPart />} />
                                        </Route>
                                    </Route>
                                    <Route path="pay" element={<Pay stripePromise={stripePromise} />}>
                                        <Route index element={<PaymentSelect />} />
                                        <Route path=":intentId/card" element={<StripePayByCardForm />} />
                                    </Route>
                                    <Route path=":intentId/processing" element={<Processing />} />
                                    <Route path=":intentId/success" element={<PaymentSuccess />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Route>
            <Route path="/error">
                <Route index element={<ErrorPage />} />
                <Route path="unsupported-order" element={<ErrorPage errorType="POS_ORDER_UNSUPPORTED" />} />
                <Route path="merchant/:merchantId/location/:locationId/order/:orderId/">
                    <Route path="payment-state-invalid" element={<ErrorPage errorType="PAYMENT.STATE_INVALID" />} />
                    <Route path="cant-load-order" element={<ErrorPage errorType="ORDER.NOT_AVAILABLE" />} />
                    <Route path="cant-load-network" element={<ErrorPage errorType="NETWORK_UNAVAILABLE" />} />
                </Route>
            </Route>
            <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
    );
}

export default Router;
