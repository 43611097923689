import React from 'react';
import Page from 'components/Page';
import { Button, Heading, HStack, IconButton, Image, Text, VStack } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { useRoutesNavigate } from 'hooks/useRoutesNavigate';
import { useBasket } from '../../contexts/basket-provider/BasketProvider';
import { FormattedCurrencyNumber } from '../../../components/CurrencyFormat';
import { BasketActions } from '../../contexts/basket-provider/BasketReducer.types';

function SelectGuestsToPayFor() {
    const navigate = useRoutesNavigate();

    const { basket, updateBasket } = useBasket();
    const {
        orderPaymentIntention: { partySize },
        parties,
    } = basket.payment;

    // Redirect if parties or partySize is not set (should be set by basket reducer)
    if (partySize == null || parties == null) return <Navigate to="../select-total-guests" replace />;

    const totalDueForParty = Math.ceil(basket.orderTotal / partySize);
    const totalDue = totalDueForParty * parties;

    return (
        <Page
            showBack
            title="Pay for your part"
            footer={
                <Button
                    isFullWidth
                    data-cy="payAll"
                    onClick={() => {
                        updateBasket({ type: BasketActions.RemainingOrAmount });
                        navigate('ReviewPayment');
                    }}
                >
                    Pay &nbsp;
                    <FormattedCurrencyNumber amount={totalDue} currency={basket.payment.currency} />
                </Button>
            }
            onBackClick={() => navigate('SelectTotalGuests')}
        >
            <VStack>
                {parties >= 1 && <Image mt="16" h="44" src={`/img/group-${parties <= 5 ? parties : 5}.svg`} />}
                <Heading pt="5" as="h2" size="md" textAlign="center">
                    For how many guests do you want to pay?
                </Heading>
                <HStack align="center" spacing="8">
                    <IconButton
                        variant="secondary"
                        aria-label="Remove Party Member"
                        icon={<MinusIcon />}
                        color="black"
                        disabled={parties === 1}
                        onClick={() => updateBasket({ type: BasketActions.RemovePartyMember })}
                    />
                    <Text fontSize="5xl" as="strong">
                        {parties}
                    </Text>
                    <IconButton
                        variant="secondary"
                        aria-label="Add Party Member"
                        icon={<AddIcon />}
                        color="black"
                        disabled={parties === partySize}
                        onClick={() => updateBasket({ type: BasketActions.AddPartyMember })}
                    />
                </HStack>
                <VStack mt="-3" spacing="-0.5">
                    <Text fontWeight="bold">
                        <FormattedCurrencyNumber amount={totalDue} currency={basket.payment.currency} />
                    </Text>
                    <Text fontSize="sm"> Your part </Text>
                </VStack>
            </VStack>
        </Page>
    );
}

export default SelectGuestsToPayFor;
