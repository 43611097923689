import React, { useEffect } from 'react';
import { useSetState } from 'providers/AppStateProvider';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetOrderAndLocationQuery, Merchant, Location, OrderV0 } from 'utils/generated';
import OrderHelper from 'helpers/OrderHelper';
import { useQueryClient } from 'react-query';
import useCurrentPath, { MATCH_ROUTES } from 'helpers/useCurrentPath';
import LoadingOrder from 'components/LoadingOrder';

function OrderContainer() {
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const { isCurrentPath } = useCurrentPath(MATCH_ROUTES.ORDER);
    const location = useLocation();

    const { merchantId, locationId, orderId } = useParams();

    const orderUrl = `/merchant/${merchantId}/location/${locationId}/order/${orderId}`;

    const { setOrder, setPayment, setMerchant, setLocation } = useSetState();

    const { isLoading } = useGetOrderAndLocationQuery(
        {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            merchantId: merchantId!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            orderId: orderId!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            locationId: locationId!,
        },
        {
            onSuccess: (res) => {
                if (res.getOrder && merchantId && locationId) {
                    const orderHelper = new OrderHelper(res.getOrder as OrderV0);
                    const newPaymentState = orderHelper.paymentInput(merchantId, locationId);
                    setOrder(res.getOrder as OrderV0);
                    setMerchant(res.getLocationPublic.merchant as Merchant);
                    setLocation(res.getLocationPublic as Location);
                    setPayment(newPaymentState);
                }
            },
            onError: () => navigate(`/error${orderUrl}/cant-load-order`),
        }
    );

    // Resets the query if returning to the order screen.
    useEffect(() => {
        if (isCurrentPath) {
            queryClient.refetchQueries({
                queryKey: ['GetOrderAndLocation'],
            });
        }
        // Location is in the dependencies but not used, this check needs to run on location change.
    }, [isCurrentPath, location, queryClient]);

    if (isLoading) return <LoadingOrder />;

    return <Outlet />;
}

export default OrderContainer;
