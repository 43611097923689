import { Text, useTheme, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Currency, GetOrderAndLocationQuery } from '../../../../utils/generated';
import { calculateTipAtPercentage } from '../../../contexts/Order.utils';
import { FormattedCurrencyNumber } from '../../../../components/CurrencyFormat';
import MultiSelectCardGrid from '../../../../components/MultiSelectCardGrid';

interface TipCardProps {
    // eslint-disable-next-line react/require-default-props
    serviceChargeConfig?: GetOrderAndLocationQuery['getLocationPublic']['serviceChargeConfiguration'];
    amount: number;
    handleTipUpdate: (percentage: number) => void;
    currency: Currency;
}

function TipsCards({ serviceChargeConfig, amount, handleTipUpdate, currency }: TipCardProps) {
    const { colors } = useTheme();
    const [tipSelected, setTipSelected] = useState<number | null>(null);
    const tipsEnabled = serviceChargeConfig?.enabled;

    if (!tipsEnabled) return null;

    const tipCard = (percent: number) => ({
        value: percent,
        element: (
            <VStack
                p="3"
                m="1"
                spacing="-1"
                border={`${percent === tipSelected ? '2px solid #3063E9' : `1.5px solid ${colors.neutral.n400}`}`}
                borderRadius="8px"
                as="button"
                width="100%"
            >
                <Text fontWeight="bold" fontSize="xl">{`${percent}%`}</Text>
                <Text fontSize="md">
                    <FormattedCurrencyNumber
                        amount={amount + calculateTipAtPercentage(percent, amount)}
                        currency={currency}
                    />
                </Text>
            </VStack>
        ),
    });

    const tipAmounts = [
        serviceChargeConfig?.percentageLow,
        serviceChargeConfig?.percentageMedium,
        serviceChargeConfig?.percentageHigh,
    ]
        .filter((entry) => entry !== undefined)
        .map((percent) => tipCard(Number(percent)));

    return (
        <>
            {/* TODO Check, but I remember mumblings of people wanting this removed */}
            {/* <Text mb="3" align="center"> */}
            {/*    100% of tip goes into your server&apos;s pocket */}
            {/* </Text> */}
            <MultiSelectCardGrid<number>
                items={tipAmounts}
                onChange={(percentage) => {
                    setTipSelected(percentage);
                    handleTipUpdate(percentage);
                }}
            />
        </>
    );
}

export default TipsCards;
