import React from 'react';
import { Button, Heading, HStack, IconButton, Image, Text, VStack } from '@chakra-ui/react';
import { MinusIcon, AddIcon } from '@chakra-ui/icons';
import Page from 'components/Page';
import { useNavigate, useParams } from 'react-router-dom';
import CurrencyFormat from 'components/CurrencyFormat';
import useSplitInEqualParts from 'helpers/useSplitInEqualParts';

function SelectMyPart() {
    const navigate = useNavigate();
    const { merchantId, locationId, orderId } = useParams();

    const {
        parties,
        amountDueForSelected,
        numberOfpartyMembersToPay,
        incrementParties,
        decrementParties,
        updateTotal,
    } = useSplitInEqualParts();

    const handlePayClick = () => {
        updateTotal();
        navigate(`/merchant/${merchantId}/location/${locationId}/order/${orderId}/pay`);
    };

    return (
        <Page
            showBack
            backUrl={`/merchant/${merchantId}/location/${locationId}/order/${orderId}`}
            title="Pay for your part"
            footer={
                <Button isFullWidth data-cy="payAll" onClick={handlePayClick}>
                    Pay &nbsp;
                    <CurrencyFormat amount={amountDueForSelected} />
                </Button>
            }
        >
            <VStack>
                {parties >= 1 && <Image mt="16" h="44" src={`/img/group-${parties <= 5 ? parties : 5}.svg`} />}
                <Heading pt="5" as="h2" size="md" textAlign="center">
                    For how many guests do you want to pay?
                </Heading>
                <HStack align="center" spacing="8">
                    <IconButton
                        variant="secondary"
                        aria-label="Remove"
                        icon={<MinusIcon />}
                        color="black"
                        disabled={parties === 1}
                        onClick={() => decrementParties()}
                    />
                    <Text fontSize="5xl" as="strong">
                        {parties}
                    </Text>
                    <IconButton
                        variant="secondary"
                        aria-label="Add"
                        icon={<AddIcon />}
                        color="black"
                        disabled={parties === numberOfpartyMembersToPay}
                        onClick={() => incrementParties()}
                    />
                </HStack>
                <VStack mt="-3" spacing="-0.5">
                    <Text fontWeight="bold">
                        <CurrencyFormat amount={amountDueForSelected} />
                    </Text>
                    <Text fontSize="sm"> Your part </Text>
                </VStack>
            </VStack>
        </Page>
    );
}

export default SelectMyPart;
