import React from 'react';
import { Button, VStack } from '@chakra-ui/react';
import { useStateData } from 'providers/AppStateProvider';
import OrderHelper from 'helpers/OrderHelper';

import Page from 'components/Page';
import OrderItemGrid from 'components/OrderItemGrid';
import { Merchant, PaymentInput } from 'utils/generated';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import CurrencyFormat from 'components/CurrencyFormat';
import Card from 'components/Card';
import MinimumSpendButtonWrapper from 'components/MinimumSpendButtonWrapper';
import ErrorAlert from 'components/ErrorAlert';
import { captureException } from 'helpers/sentryLogger';
import ShareButton from '../../components/ShareButton';
import MenuButton from './MenuButton';
import OrderTotalTable from './OrderTotalTable';

function OrderDetail() {
    const navigate = useNavigate();

    const locationState = useLocation().state as { redirectReason: string; [key: string]: string } | null;

    const { merchantId, locationId, orderId } = useParams();

    const orderUrl = `/merchant/${merchantId}/location/${locationId}/order/${orderId}`;

    const { state } = useStateData();
    const { order } = state ?? {};

    if (!order) {
        return <Navigate to={`/error${orderUrl}/cant-load-order`} />;
    }

    const { posTableName, lineItems, completed } = order;

    const { businessName, status } = state?.merchant as Merchant;
    const { amount } = state?.payment as PaymentInput;

    const showPayButtons = status.paymentsEnabled && (completed || amount <= 0);

    const orderHelper = new OrderHelper(order);

    const lineItemDetails = orderHelper.lineItemsDetails();

    const handleClickPay = () => {
        navigate('pay');
    };

    if (locationState) {
        captureException(new Error(locationState.redirectReason), { intentId: locationState.intentId });
    }

    const paymentIntentInOrderPayments = order?.payments?.some(
        (payment) => payment?.processorMetadata?.paymentIntentId === locationState?.intentId
    );

    return (
        <Page
            title={businessName}
            titleLarge
            footer={
                <VStack w="100%" spacing="4">
                    {!showPayButtons && (
                        <MinimumSpendButtonWrapper>
                            <Button isFullWidth data-cy="payAll" onClick={handleClickPay}>
                                Pay &nbsp;
                                <CurrencyFormat amount={orderHelper.totalDue()} />
                            </Button>
                            <Button
                                isFullWidth
                                variant="outline"
                                data-cy="split-by-select"
                                onClick={() => navigate('split')}
                            >
                                Split
                            </Button>
                        </MinimumSpendButtonWrapper>
                    )}
                    {showPayButtons && (
                        <ShareButton
                            isFullWidth
                            variant="secondary"
                            url={`${window.location.origin}/merchant/${merchantId}/location/${locationId}/order/${orderId}`}
                        />
                    )}
                </VStack>
            }
        >
            <VStack gap="1">
                {!showPayButtons && state?.location?.menuUrl && <MenuButton url={state?.location?.menuUrl} />}
                <Card>
                    <OrderItemGrid
                        lineItems={lineItems}
                        tableName={posTableName || ''}
                        lineItemDetails={lineItemDetails}
                    />
                    <OrderTotalTable order={orderHelper} />
                </Card>
                {locationState && !paymentIntentInOrderPayments && (
                    <ErrorAlert
                        title="Payment interrupted"
                        message="The payment attempt may temporarily appear on your statement but will be refunded automatically. You can try again or make the payment with your server."
                    />
                )}
            </VStack>
        </Page>
    );
}

export default OrderDetail;
