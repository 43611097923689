import React from 'react';
import { Text, Image, Heading } from '@chakra-ui/react';
import Page from 'components/Page';
import MenuButton from 'pages/order/MenuButton';
import { useStateData } from 'providers/AppStateProvider';

interface Props {
    tableName?: string;
}

function NoOrders({ tableName }: Props) {
    const { state } = useStateData();

    return (
        <Page title={state?.merchant?.businessName} titleLarge>
            {state?.location?.menuUrl && <MenuButton url={state?.location?.menuUrl} />}
            <Heading as="h3" mt="2" size="md" alignSelf="start" pl="2" pt="2">
                {tableName}
            </Heading>
            <Image mt="16" w="full" h="36" src="/img/empty-table.svg" />
            <Text mt="16" w="full" fontWeight="bold" align="center" fontSize="md">
                There is no bill available for this table.
            </Text>
            <Text mt="3" w="full" align="center" fontSize="md">
                Please ask your server for assistance.
            </Text>
        </Page>
    );
}

export default NoOrders;

NoOrders.defaultProps = {
    tableName: undefined,
};
