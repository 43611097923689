import React from 'react';
import { VStack, Text, Button, HStack, Td, Flex, Tr, Table, Tbody, Heading } from '@chakra-ui/react';
import QRCode from 'react-qr-code';
import { FormattedOptionalCurrency } from 'components/CurrencyFormat';
import Card from '../../components/Card';
import ReceiptPage from './ReceiptPage';
import ReceiptItems from './ReceiptItems';
import TitleAmountRow from '../../components/TitleAmountRow';
import { useOrderSummary } from './useOrderSummary.hook';
import { GetOrderForReceiptQuery } from '../../utils/generated';

type Props = {
    order: NonNullable<GetOrderForReceiptQuery['getStoreOrder']>;
};

function Receipt({ order }: Props) {
    const {
        orderSummary,
        internalReference,
        reference,
        receiptHeader,
        additionalReceiptText,
        hasNext,
        hasPrevious,
        nextOrderSummary,
        previousOrderSummary,
    } = useOrderSummary(order);

    // Update the ref to include the hyphen for collection
    const url = new URL(window.location.href);
    if (internalReference) {
        url.searchParams.set('r', internalReference);
    }

    const embedMode = url.searchParams.get('embed');
    const multipart = hasPrevious || hasNext;

    const qrCodeValue = url.toString();
    return (
        <ReceiptPage
            internalReference={internalReference}
            reference={reference}
            receiptHeader={receiptHeader}
            embedMode={embedMode != null}
        >
            <HStack justifyContent="space-between" mb="4">
                <div>{hasPrevious && <Button onClick={previousOrderSummary}>Previous</Button>}</div>
                <div>{hasNext && <Button onClick={nextOrderSummary}>Next</Button>}</div>
            </HStack>
            {(!embedMode || multipart) && (
                <Heading
                    size="md"
                    w="100"
                    textAlign="center"
                    fontWeight="bold"
                    as="h3"
                    bg="black"
                    color="white"
                    rounded="md"
                    mb="4"
                    py="2"
                >
                    {orderSummary.orderTypeName.toLowerCase()}
                </Heading>
            )}
            <VStack gap="10">
                <Card>
                    <ReceiptItems currency={order.currency} lineItems={orderSummary.lineItems} />
                    <Table variant="simple" size="sm">
                        <Tbody>
                            <TitleAmountRow
                                title={
                                    orderSummary.lineItems.length > 1
                                        ? `${orderSummary.orderTypeName} Subtotal`
                                        : 'Subtotal'
                                }
                                currency={order.currency}
                                amount={orderSummary.amount}
                            />
                            {orderSummary.lineItems.length > 1 && (
                                <TitleAmountRow
                                    title="Order total"
                                    currency={order.currency}
                                    amount={order.amount}
                                    strong
                                />
                            )}

                            {order?.payments?.map((x) => (
                                <Tr key={x.id}>
                                    <Td border="none">
                                        <Flex direction="row" align="flex-end" justify="flex-start">
                                            <Text fontWeight="400" fontSize="sm">
                                                Card payment
                                            </Text>
                                        </Flex>
                                    </Td>
                                    <Td border="none" textAlign="right">
                                        <Flex flexDirection="row" align="flex-end" justify="flex-end">
                                            {x?.cardDetails?.last4 && (
                                                <Text mt="1" mr="3" fontWeight="200" fontSize="smaller">
                                                    {`${
                                                        x.processorDetails?.transactionCode
                                                            ? `${x.processorDetails?.transactionCode} - `
                                                            : null
                                                    }Card ${x?.cardDetails?.last4}`}
                                                </Text>
                                            )}
                                            <FormattedOptionalCurrency currency={order.currency} amount={x.amount} />
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))}
                            <TitleAmountRow
                                title="Paid"
                                currency={order.currency}
                                amount={order?.payments?.reduce((acc, x) => x.amount + acc, 0) ?? 0}
                                strong
                            />
                        </Tbody>
                    </Table>
                </Card>
                {internalReference !== 'UNKNOWN' && !embedMode && (
                    <Card stretch={false}>
                        <QRCode
                            size={256}
                            style={{ height: 'auto', maxWidth: '150px', width: '100%', margin: '0 auto' }}
                            value={qrCodeValue}
                            viewBox="0 0 256 256"
                            data-testid="fulfilment-qr-code"
                        />
                    </Card>
                )}
                {additionalReceiptText && !embedMode && (
                    <Text fontWeight="bold" fontSize="md" as="h2" textAlign="center">
                        {additionalReceiptText}
                    </Text>
                )}
            </VStack>
        </ReceiptPage>
    );
}

export default Receipt;
