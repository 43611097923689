import { useCallback } from 'react';
import { OgPaymentInput } from 'typings/AppStateProvider';
import { PaymentSetupMutationVariables, ServicePaymentType } from 'utils/generated';

function useReshapedPayment() {
    const reshape = useCallback((payment: OgPaymentInput, stripePaymentIntentId?: string) => {
        const {
            amount,
            currency,
            orderPaymentIntention,
            consumerName,
            lineItems,
            parties,
            servicePayment,
            orderId,
            merchantId,
        } = payment;

        return {
            merchantId,
            orderId,
            payment: {
                amount,
                currency,
                orderPaymentIntention,
                consumerName,
                lineItems,
                parties,
                servicePayment: servicePayment?.type === ServicePaymentType.ServiceCharge ? undefined : servicePayment,
            },
            paymentProcessorData: {
                stripePaymentIntentId,
            },
        } as PaymentSetupMutationVariables;
    }, []);

    return { reshape };
}

export default useReshapedPayment;
