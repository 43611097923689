import { StripeError } from '@stripe/stripe-js';
import { Params } from 'react-router-dom';
import { captureException } from './sentryLogger';

export default class StripePaymentError {
    message: string;

    errorCode: string;

    errorType: string;

    zipCodeName: string;

    constructor(error: StripeError, country?: string, params?: Readonly<Params<string>>) {
        this.message = error.message ?? '';
        this.errorCode = error.code ?? '';
        this.errorType = error.type;
        this.zipCodeName = country && ['GB', 'IE'].includes(country) ? 'postcode' : 'zip code';

        // Root error types
        const dontLogErrorTypes = [
            // Card details invalid
            'validation_error',
            // Issue charging customer's card
            'card_error',
        ];

        // Either no type specified, or type=invalid_request_error
        const dontLogErrorCodes = [
            // Issue charging customer's card
            'card_decline_rate_limit_exceeded',
            // 3DS failure
            'payment_intent_authentication_failure',
        ];

        // Log if the error type and code does not appear in our blacklist
        const shouldLog = !dontLogErrorTypes.includes(this.errorType) && !dontLogErrorCodes.includes(this.errorCode);
        if (shouldLog) {
            const tags = { stripe_error_type: String(error.type), stripe_error_code: error.code };
            captureException(error, tags, params);
        }

        // Build error message
        // Use a clearer error message when failure is due to incorrect postcode
        if (this.errorCode === 'incorrect_zip') {
            this.message = `The ${this.zipCodeName} you supplied failed validation. Please ensure you are using the correct billing ${this.zipCodeName} for this card.`;
        }

        // Use a clearer error message when failure is due to incomplete postcode
        if (this.errorCode === 'incomplete_zip') {
            this.message = `The ${this.zipCodeName} for the payment card is incomplete. Please enter the full billing ${this.zipCodeName} for this card in the form above.`;
        }

        // If the card payment is blocked, add a message stating that it may still appear on the statement
        const isCardError = error.type === 'card_error';
        if (isCardError) {
            this.message = `${this.message} This payment attempt may appear as a completed transaction on your statement
                for several hours even though you have not been charged.`;
        }
    }
}
