import React from 'react';
import { Button, Heading, HStack, IconButton, Image, Text, VStack } from '@chakra-ui/react';
import { MinusIcon, AddIcon } from '@chakra-ui/icons';
import Page from 'components/Page';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import useSplitInEqualParts from 'helpers/useSplitInEqualParts';
import MinimumSpendButtonWrapper from 'components/MinimumSpendButtonWrapper';
import CurrencyFormat from 'components/CurrencyFormat';

function SplitEqualParts() {
    const navigate = useNavigate();
    const { partySize, numberOfpartyMembersPaid, amountDueForSelected, incrementPartySize, decrementPartySize } =
        useSplitInEqualParts();

    function handleContinue() {
        navigate('my-part', { replace: true });
    }

    if (!partySize) return <LoadingSpinner />;

    return (
        <Page
            showBack
            title="Guest number"
            footer={
                <MinimumSpendButtonWrapper>
                    <Button isFullWidth data-cy="payAll" onClick={() => handleContinue()}>
                        Continue
                    </Button>
                </MinimumSpendButtonWrapper>
            }
        >
            <VStack>
                {partySize >= 1 && <Image mt="16" h="44" src={`/img/group-${partySize <= 5 ? partySize : 5}.svg`} />}
                <Heading pt="5" as="h2" size="md" textAlign="center">
                    Select the number of guests
                </Heading>
                <HStack align="center" spacing="8">
                    <IconButton
                        variant="secondary"
                        aria-label="Remove"
                        icon={<MinusIcon />}
                        color="black"
                        disabled={partySize === 1 || numberOfpartyMembersPaid > 0}
                        onClick={() => decrementPartySize()}
                    />
                    <Text fontSize="5xl" as="strong">
                        {partySize}
                    </Text>
                    <IconButton
                        variant="secondary"
                        aria-label="Add"
                        icon={<AddIcon />}
                        color="black"
                        disabled={numberOfpartyMembersPaid > 0}
                        onClick={() => incrementPartySize()}
                    />
                </HStack>
                <VStack mt="-3" spacing="-0.5">
                    <Text fontWeight="bold">
                        <CurrencyFormat amount={amountDueForSelected} />
                    </Text>
                    <Text fontSize="sm">Paid by each guest</Text>
                </VStack>
            </VStack>
        </Page>
    );
}

export default SplitEqualParts;
