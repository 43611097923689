import React, { Fragment } from 'react';
import { Grid, Text, Box, useTheme, Flex, Heading, GridItem, HStack, Badge } from '@chakra-ui/react';
import { Currency } from 'utils/generated';
import { FormattedCurrencyNumber } from '../../../../components/CurrencyFormat';
import { useDisplayData } from '../../../contexts/DisplayDataProvider';

interface Props {
    tableName: string;
    currency: Currency;
}

function OverviewOrderItems({ tableName, currency }: Props) {
    const { colors } = useTheme();
    const { lineItemsWithQuantities } = useDisplayData();

    return (
        <>
            <Flex px="4" pt="4" direction="row" align="center" justify="space-between">
                <Heading size="md" as="h1" data-cy="orderReference">
                    {`${tableName.match('^[0-9]*$') ? `Table ${tableName}` : tableName}`}
                </Heading>
            </Flex>
            <Box data-cy="orderItemGrid" overflow="auto">
                <Grid px="4" templateColumns="1fr 10fr 1fr" gap="1">
                    {Object.entries(lineItemsWithQuantities).map(([posId, item]) => (
                        <Fragment key={posId}>
                            <Text fontWeight="500" fontSize="sm">
                                {item.originalQuantity}x
                            </Text>

                            <Text fontWeight="500" fontSize="sm">
                                {item.name}
                            </Text>

                            <GridItem>
                                <HStack justifyContent="space-between">
                                    {item.purchasedQuantity && (
                                        <Badge
                                            borderRadius="2xl"
                                            m="0"
                                            fontWeight="400"
                                            textTransform="unset"
                                            px="1.5"
                                            py="0.5"
                                        >
                                            {item.remainingQuantity !== 0 ? `${item.purchasedQuantity} paid` : 'Paid'}
                                        </Badge>
                                    )}
                                    <Text w="full" fontWeight="600" fontSize="sm" align="right">
                                        <FormattedCurrencyNumber
                                            amount={item.originalQuantity * item.singleItemAmount}
                                            currency={currency}
                                        />
                                    </Text>
                                </HStack>
                            </GridItem>
                            <GridItem colSpan={2} colStart={2}>
                                {item.childItems &&
                                    item.childItems.map((childItem, childItemIndex) => (
                                        <Text
                                            key={childItem.name + String(childItemIndex)}
                                            gridColumnStart="2"
                                            color={colors.neutral.n700}
                                            fontSize="sm"
                                        >
                                            {childItem.quantity > 1 && `${childItem.quantity}x`} {childItem.name}
                                        </Text>
                                    ))}
                            </GridItem>
                        </Fragment>
                    ))}
                </Grid>
            </Box>
        </>
    );
}

export default OverviewOrderItems;
