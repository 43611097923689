import React from 'react';
import { Table, Tbody } from '@chakra-ui/react';
import TitleAmountRow from 'components/TitleAmountRow';
import PaymentRow from 'components/PaymentRow';
import { OverviewPaymentItem } from '../../../contexts/Order.utils';
import { useDisplayData } from '../../../contexts/DisplayDataProvider';

export const PaidForText = (item: OverviewPaymentItem) => {
    if (!item.parties && !item.lineItems) return '';
    if (item?.parties && item.parties > 1) return ` - Paid for ${item.parties} people`;
    if (item?.parties && item.parties === 1) return ' - Paid for 1 person';
    if (item.lineItems && item.lineItems.length === 1) return ` - Paid for 1 item`;
    if (item.lineItems && item.lineItems.length > 1) return ` - Paid for ${item.lineItems.length} items`;
    return '';
};

function OverviewPayments() {
    const { concatenatedPayments, totalDue, subTotal, serviceTotal } = useDisplayData();

    return (
        <Table variant="simple" size="sm">
            <Tbody>
                <TitleAmountRow title="Subtotal" amount={subTotal} />
                {serviceTotal !== 0 && <TitleAmountRow title="Service Charge" amount={serviceTotal} />}
                <TitleAmountRow title="Total" amount={subTotal + serviceTotal} strong />

                {concatenatedPayments.map((x, index) => (
                    <PaymentRow
                        key={String(x.amount + x.name + index)}
                        title={x.name}
                        paidFor={PaidForText(x)}
                        lastFour={x.lastFour}
                        amount={x.amount}
                    />
                ))}

                <TitleAmountRow title="Due" amount={totalDue} strong />
            </Tbody>
        </Table>
    );
}

export default OverviewPayments;
