import { useParams, useSearchParams } from 'react-router-dom';
import { Button, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import QRCode from 'react-qr-code';
import React from 'react';
import { Currency, useGetOapOrderForReceiptQuery } from '../../utils/generated';
import LoadingReceipt from './LoadingReceipt';
import ReceiptPage from './ReceiptPage';
import { FormattedOptionalCurrency } from '../../components/CurrencyFormat';
import Card from '../../components/Card';
import Receipt from './Receipt';

function V0ReceiptContainer() {
    const { orderId } = useParams() as Record<string, string>;

    const [searchParams] = useSearchParams();

    const orderRef = searchParams.get('r') ?? undefined;
    const amount = searchParams.get('a');
    const currency = searchParams.get('c');

    // On error handler added to options to override global error handling which toasts on other routes.
    const { data, isFetching } = useGetOapOrderForReceiptQuery(
        {
            orderId,
        },
        {
            onError: (e) => e,
        }
    );

    if (isFetching && !data) {
        return <LoadingReceipt />;
    }

    const qrCodeValue = `${window.location.origin}/r/${orderId}?&r=${orderRef}&a=${amount}&c=${currency}`;

    const order = data?.getCompletedOrder;

    const currencyFromParams = Currency[currency as keyof typeof Currency] ?? undefined;

    if (!order) {
        return (
            <ReceiptPage internalReference={orderRef}>
                <VStack h="full" gap="5">
                    <VStack>
                        {amount != null && (
                            <>
                                <Text mt="16" textAlign="center">
                                    Amount
                                </Text>
                                <Heading mb="2" align="center" size="3xl">
                                    <FormattedOptionalCurrency amount={+amount} currency={currencyFromParams} />
                                </Heading>
                            </>
                        )}
                    </VStack>
                    <Heading size="lg" fontWeight="700" as="h2" textAlign="center">
                        We are preparing your collection slip.
                    </Heading>

                    <Card stretch={false}>
                        <QRCode
                            size={256}
                            style={{ height: 'auto', maxWidth: '150px', width: '100%', margin: '0' }}
                            value={qrCodeValue}
                            viewBox="0 0 256 256"
                        />
                    </Card>

                    <HStack mt="16" align="center">
                        <Button onClick={() => window.location.reload()}>Refresh</Button>
                    </HStack>
                </VStack>
            </ReceiptPage>
        );
    }

    return <Receipt order={order} />;
}

export default V0ReceiptContainer;
