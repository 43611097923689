import { useState } from 'react';
import { separateOrderByFulfilmentDetails } from './Receipt.utils';
import { GetOapOrderForReceiptQuery } from '../../utils/generated';

export const createReceiptMeta = (order: NonNullable<GetOapOrderForReceiptQuery['getCompletedOrder']>) => {
    const receiptHeader = order.receiptConfiguration?.receiptHeaderText ?? null;
    const receiptFooter = order.receiptConfiguration?.additionalReceiptText ?? null;

    const fiscalizationHeaderPieces: Array<string | undefined | null> = [];
    const fiscalizationFooterPieces: Array<string | undefined | null> = [];

    order.payments.forEach((payment) => {
        if (payment.fiscalization) {
            fiscalizationFooterPieces.push(payment.fiscalization.receiptAdditions?.digital.footer);
            fiscalizationHeaderPieces.push(payment.fiscalization.receiptAdditions?.digital.header);
        }
    });

    const fiscalizationHeader = fiscalizationHeaderPieces.filter((piece) => piece).join('\n');
    const fiscalizationFooter = fiscalizationFooterPieces.filter((piece) => piece).join('\n');

    return {
        receiptHeader,
        receiptFooter,
        fiscalizationFooter,
        fiscalizationHeader,
    };
};

// eslint-disable-next-line import/prefer-default-export
export const useOrderSummary = (order: NonNullable<GetOapOrderForReceiptQuery['getCompletedOrder']>) => {
    const [currentOrderSummaryIndex, setCurrentOrderSummaryIndex] = useState(0);
    const summaries = separateOrderByFulfilmentDetails(order);
    const orderSummary = summaries[currentOrderSummaryIndex];

    const hasNext = currentOrderSummaryIndex < summaries.length - 1;
    const hasPrevious = currentOrderSummaryIndex > 0;

    const reference = orderSummary.fulfilmentDetail.reference ?? null;

    const nextOrderSummary = () => {
        setCurrentOrderSummaryIndex((index) => index + 1);
    };

    const previousOrderSummary = () => {
        setCurrentOrderSummaryIndex((index) => index - 1);
    };

    const { receiptHeader, receiptFooter, fiscalizationHeader, fiscalizationFooter } = createReceiptMeta(order);

    return {
        orderSummary,
        internalReference: orderSummary.fulfilmentDetail.id,
        receiptHeader,
        additionalReceiptText: receiptFooter,
        fiscalizationHeader,
        fiscalizationFooter,
        reference,
        hasNext,
        hasPrevious,
        nextOrderSummary,
        previousOrderSummary,
    };
};
