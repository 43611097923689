import { Badge, HStack, Text, IconButton, Grid, GridItem } from '@chakra-ui/react';
import { MinusIcon, AddIcon } from '@chakra-ui/icons';
import React from 'react';
import colors from 'utils/theme/colors';
import { SplitItem } from 'typings/splitItem';
import { commaSeparateItems, showQuantityIfMoreThanOne } from 'helpers/textFormating';
import { useIntl } from 'react-intl';
import { Currency } from 'utils/generated';
import { FormattedCurrencyNumber } from './CurrencyFormat';

interface Props {
    itemId: string;
    itemProps: SplitItem;
    add: (id: string) => void;
    remove: (id: string) => void;
    currency: Currency;
    quantity?: number;
}

function ItemWithQuantitySelect({ itemId, itemProps, quantity, add, remove, currency }: Props) {
    const convertCents = (cents: number) => (cents > 0 ? cents / 100 : 0);

    const intl = useIntl();
    return (
        <Grid
            p="2"
            m="0"
            borderRadius="2xl"
            bgColor={quantity ? '#F0F6FF' : 'white'}
            templateColumns="1.5fr 1fr"
            gap="0"
            w="full"
        >
            <HStack justify="flex-start" gridColumnStart="1" fontWeight="500" fontSize="sm">
                <Badge
                    display="flex"
                    borderRadius="2xl"
                    minH="6"
                    minW="6"
                    fontSize="xs"
                    alignItems="center"
                    color={quantity ? 'white' : 'black'}
                    bgColor={quantity ? '#3063E9' : '#E6E6E6'}
                >
                    <Text w="full" textAlign="center">
                        {quantity ?? itemProps.remainingQuantity}
                    </Text>
                </Badge>
                <Text textAlign="left" fontWeight="500" fontSize="sm">
                    {itemProps.name}
                </Text>
            </HStack>
            <HStack justify="flex-end" gridColumnStart="2" gridColumnEnd="end">
                <Text gridColumnStart="2" gridColumnEnd="end" fontWeight="600" fontSize="sm" align="right">
                    <FormattedCurrencyNumber
                        amount={itemProps.singleItemAmount * (quantity ?? 0)}
                        currency={currency}
                    />
                </Text>
                <IconButton
                    size="xs"
                    minH="8"
                    minW="8"
                    disabled={!quantity || quantity === 0}
                    onClick={() => remove(itemId)}
                    aria-label={`decrement ${itemProps.name} count`}
                    variant="outline"
                    borderColor="#999999"
                >
                    <MinusIcon />
                </IconButton>
                <IconButton
                    size="xs"
                    minH="8"
                    minW="8"
                    disabled={(quantity && quantity >= itemProps.remainingQuantity) || false}
                    onClick={() => add(itemId)}
                    aria-label={`increment ${itemProps.name} count`}
                    variant="outline"
                    borderColor="#999999"
                >
                    <AddIcon />
                </IconButton>
            </HStack>
            {itemProps.childItems && (
                <GridItem mx="8" colSpan={2} colStart={1}>
                    <HStack spacing="1" fontSize="xs" textOverflow="ellipsis">
                        {itemProps.childItems.map((childItem, childItemIndex) => (
                            <Text key={childItem.name + String(childItemIndex)} color={colors.neutral.n600}>
                                {showQuantityIfMoreThanOne(childItem.quantity, childItem.name)}
                                {commaSeparateItems(childItemIndex, itemProps.childItems.length)}
                            </Text>
                        ))}
                    </HStack>
                </GridItem>
            )}
            <GridItem mx="8" colSpan={2} colStart={1}>
                <HStack spacing={1}>
                    <Text fontSize="xs">
                        {itemProps.remainingQuantity - (quantity || 0) > 0 &&
                            `${itemProps.remainingQuantity - (quantity || 0)} left to pay @`}
                        {`${intl.formatNumber(convertCents(itemProps.singleItemAmount), {
                            style: 'currency',
                            currencySign: 'accounting',
                            currency,
                        })} each.`}
                        &nbsp;
                        {itemProps.purchasedQuantity > 0 &&
                            ` ${itemProps.purchasedQuantity} ${
                                itemProps.purchasedQuantity > 1 ? 'have ' : ' has '
                            }already been paid for`}
                    </Text>
                </HStack>
            </GridItem>
        </Grid>
    );
}

ItemWithQuantitySelect.defaultProps = {
    quantity: undefined,
};

export default ItemWithQuantitySelect;
