import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { OrderHeader, PosOrderList, useGetPosTableOrdersQuery } from 'utils/generated';
import { useSetState } from 'providers/AppStateProvider';
import LoadingOrder from 'components/LoadingOrder';
import NoOrders from './NoOrders';
import { PaymentProcessor } from '../../typings/PaymentProcessor';

function QrLookup() {
    const [orderList, setOrders] = useState<OrderHeader[] | undefined | null>([]);
    const [orderTableName, setOrderTableName] = useState<string | undefined>();
    const navigate = useNavigate();
    const { setLocation, setMerchant } = useSetState();
    const { qrCode } = useParams();

    const {
        data,
        isLoading,
        error: loadingError,
    } = useGetPosTableOrdersQuery(
        { qr: qrCode! },
        {
            onError: (errors) => {
                if (Array.isArray(errors)) {
                    errors.forEach((error) => {
                        if (error.errorType === 'QR_NOT_FOUND') {
                            window.location.replace(`${process.env.REACT_APP_PORTAL_URL}/link-qr/${qrCode}/locations`);
                        }
                        if (error.errorType === 'POS_ORDER_UNSUPPORTED')
                            navigate('/error/unsupported-order', { state: { message: error.message } });
                        if (error.errorType === 'Validation') navigate('/404');
                    });
                } else {
                    throw errors;
                }
            },
        }
    );

    useEffect(() => {
        if (data) {
            const { orders, location, qrCode: qrCodeReturn } = data.getPosTableOrders as PosOrderList;
            const { processor } = location.merchant.servicePaymentProcessors.tablePay;

            if (location) {
                setLocation(location);
                setMerchant(location.merchant);
            }
            if (qrCodeReturn) {
                setOrderTableName(qrCodeReturn.tableName);
            }
            if (orders) {
                setOrders(orders);
            }
            if (processor === PaymentProcessor.SUMUP && orders.length >= 1) {
                navigate(`/op/merchant/${location.merchant.id}/location/${location.id}/order/${orders[0].id}`);
            } else if (orders.length === 1 && location.merchant.status.paymentsEnabled) {
                navigate(`/merchant/${location.merchant.id}/location/${location.id}/order/${orders[0].id}`);
            }
        }
    }, [data, navigate, setLocation, setMerchant]);

    if (isLoading) return <LoadingOrder />;

    if (orderList && orderTableName && orderList?.length <= 0) return <NoOrders tableName={orderTableName} />;

    if (loadingError) {
        return <Navigate to="/error" state={{ message: 'We could not load the order list.' }} />;
    }

    return <LoadingOrder />;
}

export default QrLookup;
