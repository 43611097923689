import React from 'react';
import { Navigate } from 'react-router-dom';
import { Chance } from 'chance';

export enum DemoType {
    Stripe = 'STRIPE',
    SumUp = 'SUMUP',
}

interface Props {
    demoType: DemoType;
}

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'prod';

function Demo({ demoType }: Props) {
    // Stops Stripe demo route from being used in prod.
    if (isProduction && demoType === DemoType.Stripe) {
        return <Navigate to="/404" />;
    }

    const chance = new Chance();
    const demoUuid = chance.guid({ version: 4 });

    const qrHeader = demoType === DemoType.SumUp ? 'TEST_SUMUP' : 'TEST';

    return <Navigate to={`/qr/${qrHeader}:${demoUuid}`} />;
}

export default Demo;
