import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';

interface Props<T> {
    onChange: (value: T) => void;
    items: { value: T; element: JSX.Element }[];
}

function MultiSelectCardGrid<T>({ onChange, items }: Props<T>) {
    const handleItemClick = (value: T) => {
        onChange(value);
    };

    return (
        <Grid w="100" templateColumns="repeat(3, 1fr)" gap={2}>
            {items.map((item) => (
                <GridItem key={String(item.value)} onClick={() => handleItemClick(item.value)}>
                    {item.element}
                </GridItem>
            ))}
        </Grid>
    );
}

export default MultiSelectCardGrid;
