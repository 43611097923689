import React, { useEffect, useState } from 'react';
import useScript from 'hooks/useScript/useScript';
import LoadingSpinner from 'components/LoadingSpinner';
import { useParams } from 'react-router-dom';
import { useRoutesNavigate } from 'hooks/useRoutesNavigate';
import Page from 'components/Page';
import { Box } from '@chakra-ui/react';
import { useStartSumUpOnlinePaymentMutation } from '../../../utils/generated';
import { errorToast } from '../../../helpers/ToastHelper';
import { useBasket } from '../../contexts/basket-provider/BasketProvider';

function Pay() {
    const navigate = useRoutesNavigate();
    const { paymentId, locationId } = useParams();
    const { basket } = useBasket();

    const [checkoutId, setCheckoutId] = useState<string | null>(null);
    const [isPaymentWidgetMounted, setPaymentWidgetMounted] = useState(false);

    const onlinePaymentsScriptStatus = useScript(process.env.REACT_APP_ONLINE_PAYMENTS_SDK, {
        removeOnUnmount: false,
    });

    const { mutate } = useStartSumUpOnlinePaymentMutation({
        onSuccess: (res) => {
            if (res.startPayment.processorMetadata?.paymentIntentId) {
                setCheckoutId(res.startPayment.processorMetadata?.paymentIntentId);
            }
        },
        onError: (e) => {
            errorToast({
                title: 'An error occurred while starting the payment. Please try again or contact us if the problem persists.',
            });
            console.error(e);
            navigate('Overview');
        },
    });

    useEffect(() => {
        if (basket.payment.amount === 0 && paymentId) {
            // If the basket is empty, we probably refreshed on the payment page
            // So we should send them to the status page instead, which will handle odd logic
            navigate('PaymentStatus', { paymentId });
            return;
        }
        if (onlinePaymentsScriptStatus === 'ready') {
            if (basket.merchantId && basket.orderId && basket.payment.currency && !checkoutId && paymentId) {
                mutate({
                    merchantId: basket.merchantId,
                    orderId: basket.orderId,
                    payment: {
                        ...basket.payment,
                        id: paymentId,
                    },
                    redirectUrl: `${window.location.origin}/op/merchant/${basket.merchantId}/location/${locationId}/order/${basket.orderId}/pay/${paymentId}`,
                });
            }
        }
    }, [basket, checkoutId, mutate, onlinePaymentsScriptStatus, paymentId, navigate, locationId]);

    useEffect(() => {
        if (checkoutId) {
            window.SumUpCard.mount({
                checkoutId,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onResponse: (type) => {
                    if (paymentId == null) {
                        navigate('Overview');
                        return;
                    }
                    if (type === 'error' || type === 'success') {
                        navigate('PaymentStatus', { paymentId });
                    }
                },
                onLoad() {
                    setPaymentWidgetMounted(true);
                },
            });
        }
    }, [checkoutId, navigate, paymentId]);

    if (onlinePaymentsScriptStatus === 'loading' || !checkoutId) return <LoadingSpinner />;

    return (
        <Page title="Pay" titleLarge showBack onBackClick={() => navigate('Overview')}>
            <Box
                id="sumup-card"
                sx={{
                    '[data-sumup-id="widget__container"]': {
                        padding: 0,
                    },
                }}
            >
                {!isPaymentWidgetMounted && <LoadingSpinner />}
            </Box>
        </Page>
    );
}

export default Pay;
