import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

function LoadingSpinner() {
    return (
        <Flex h="90vh" align="center" justify="center">
            <Spinner />
        </Flex>
    );
}

export default LoadingSpinner;
