import React, { useEffect } from 'react';
import { useStateData } from 'providers/AppStateProvider';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

function SplitByItemContainer() {
    const params = useParams();
    const { state } = useStateData();
    const { merchantId, locationId, orderId } = params;

    const splitByItemAvailable =
        state?.payment &&
        state?.payment?.parties === undefined &&
        state?.payment?.amount > 30 &&
        process.env.REACT_APP_SPLIT_BY_ITEM_DISABLED !== 'true';

    const navigate = useNavigate();

    useEffect(() => {
        if (!splitByItemAvailable) navigate(`/merchant/${merchantId}/location/${locationId}/order/${orderId}`);
    }, [locationId, merchantId, navigate, orderId, splitByItemAvailable, state]);

    return <Outlet />;
}

export default SplitByItemContainer;
