import React, { createContext, useContext, useMemo } from 'react';
import { useOrderInfo } from './OrderProvider';
import {
    formatLineItemsWithQuantities,
    FormattedLineItem,
    getBalance,
    getConcatenatedPayments,
    getSubtotal,
    getTipsPaid,
    OverviewPaymentItem,
} from './Order.utils';
import LoadingOrder from '../../components/LoadingOrder';

interface Props {
    children: React.ReactNode;
}

interface DisplayData {
    totalDue: number;
    hasDiscount: boolean;
    subTotal: number;
    serviceTotal: number;
    tipsPaid: number;
    concatenatedPayments: OverviewPaymentItem[];
    lineItemsWithQuantities: Record<string, FormattedLineItem>;
}

const DisplayDataContext = createContext<DisplayData>({
    totalDue: 0,
    hasDiscount: false,
    subTotal: 0,
    serviceTotal: 0,
    tipsPaid: 0,
    concatenatedPayments: [],
    lineItemsWithQuantities: {},
});

function DisplayDataProvider({ children }: Props) {
    const { order } = useOrderInfo();

    const value = useMemo(() => {
        if (!order) return null;
        // Combine posPayments and tablePayPayments
        const concatenatedPayments = getConcatenatedPayments(order);

        return {
            totalDue: getBalance(concatenatedPayments, order.amount ?? 0),
            hasDiscount: order.hasDiscounts ?? false,
            subTotal: getSubtotal(order), // Order amount minus service charge
            serviceTotal: order.serviceCharge?.amount ?? 0,
            tipsPaid: getTipsPaid(concatenatedPayments),
            concatenatedPayments,
            lineItemsWithQuantities: formatLineItemsWithQuantities(order, concatenatedPayments),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, order.payments.length]);

    if (!value) return <LoadingOrder />;

    return <DisplayDataContext.Provider value={value}>{children}</DisplayDataContext.Provider>;
}

export default DisplayDataProvider;

export function useDisplayData() {
    return useContext(DisplayDataContext);
}
