import { Stack } from '@chakra-ui/react';
import React from 'react';

interface Props {
    scroll?: boolean;
    children: React.ReactNode;
    stretch?: boolean;
}

function Card({ children, scroll, stretch }: Props) {
    return (
        <Stack
            maxW="md"
            w={stretch ? '100%' : 'unset'}
            borderWidth="2px"
            borderRadius="2xl"
            px={stretch ? '1' : '4'}
            py={stretch ? 'unset' : '4'}
            overflow={scroll ? 'auto' : 'hidden'}
        >
            {children}
        </Stack>
    );
}

export default Card;

Card.defaultProps = {
    scroll: false,
    stretch: true,
};
