import React from 'react';
import { useSetState } from 'providers/AppStateProvider';
import { VStack, Text, Button, HStack, Td, Flex, Tr, Table, Tbody, Heading, Box } from '@chakra-ui/react';
import QRCode from 'react-qr-code';
import Card from '../../components/Card';
import ReceiptPage from './ReceiptPage';
import ReceiptItems from './ReceiptItems';
import TitleAmountRow from '../../components/TitleAmountRow';
import { useOrderSummary } from './useOrderSummary.hook';
import { GetOapOrderForReceiptQuery, OrderV0 } from '../../utils/generated';
import CurrencyFormat from '../../components/CurrencyFormat';

type Props = {
    order: NonNullable<GetOapOrderForReceiptQuery['getCompletedOrder']>;
};

function Receipt({ order }: Props) {
    const {
        orderSummary,
        internalReference,
        reference,
        receiptHeader,
        additionalReceiptText,
        hasNext,
        hasPrevious,
        fiscalizationFooter,
        fiscalizationHeader,
        nextOrderSummary,
        previousOrderSummary,
    } = useOrderSummary(order);

    // Update the ref to include the hyphen for collection
    const url = new URL(window.location.href);
    if (internalReference) {
        url.searchParams.set('r', internalReference);
    }

    // Set the order (for currency purposes) in the app state
    const { setOrder } = useSetState();
    setOrder(order as OrderV0);

    const embedMode = url.searchParams.get('embed');
    const multiplePricebooks = hasPrevious || hasNext;
    const defaultPricebook = orderSummary.priceBook.name.toLowerCase() === 'default';

    const qrCodeValue = url.toString();
    return (
        <ReceiptPage
            internalReference={internalReference}
            reference={reference}
            receiptHeader={receiptHeader}
            embedMode={embedMode != null}
            fiscalizationHeader={fiscalizationHeader}
        >
            <HStack justifyContent="space-between" mb="4">
                <div>{hasPrevious && <Button onClick={previousOrderSummary}>Previous</Button>}</div>
                <div>{hasNext && <Button onClick={nextOrderSummary}>Next</Button>}</div>
            </HStack>
            {(!embedMode || multiplePricebooks) && !defaultPricebook && (
                <Heading
                    size="md"
                    w="100"
                    textAlign="center"
                    fontWeight="bold"
                    as="h3"
                    bg="black"
                    color="white"
                    rounded="md"
                    mb="4"
                    py="2"
                >
                    {orderSummary.priceBook.name.toLowerCase()}
                </Heading>
            )}
            <VStack gap="10">
                <Card>
                    <ReceiptItems lineItems={orderSummary.lineItems} />
                    <Table variant="simple" size="sm">
                        <Tbody>
                            {orderSummary.discount > 0 ? (
                                <TitleAmountRow title="Discount" amount={-orderSummary.discount} />
                            ) : null}
                            <TitleAmountRow
                                title={
                                    orderSummary.lineItems.length > 1
                                        ? `${orderSummary.priceBook.name} Subtotal`
                                        : 'Subtotal'
                                }
                                amount={orderSummary.amount}
                            />
                            {orderSummary.lineItems.length > 1 && (
                                <TitleAmountRow
                                    title="Order total"
                                    amount={order.amount - (order.serviceCharge ? order.serviceCharge.amount : 0)}
                                    strong
                                />
                            )}

                            {order.serviceCharge ? (
                                <TitleAmountRow title="Tip" amount={order.serviceCharge?.amount} />
                            ) : null}
                            {order?.payments?.map((x) => (
                                <Tr key={x.id}>
                                    <Td border="none">
                                        <Flex direction="row" align="flex-end" justify="flex-start">
                                            <Text fontWeight="400" fontSize="sm">
                                                {x.consumerName ?? 'Card payment'}
                                            </Text>
                                        </Flex>
                                    </Td>
                                    <Td border="none" textAlign="right">
                                        <Flex flexDirection="row" align="flex-end" justify="flex-end">
                                            {x?.cardDetails?.last4 && (
                                                <Text mt="1" mr="3" fontWeight="200" fontSize="smaller">
                                                    {`${
                                                        x.processorReference ? `${x.processorReference} - ` : null
                                                    }Card ${x?.cardDetails?.last4}`}
                                                </Text>
                                            )}
                                            <CurrencyFormat amount={x.amount} />
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))}
                            <TitleAmountRow
                                title="Paid"
                                amount={order?.payments?.reduce((acc, x) => x.amount + acc, 0) ?? 0}
                                strong
                            />
                        </Tbody>
                    </Table>
                </Card>
                {internalReference !== 'UNKNOWN' && !embedMode && (
                    <Card stretch={false}>
                        <QRCode
                            size={256}
                            style={{ height: 'auto', maxWidth: '150px', width: '100%', margin: '0 auto' }}
                            value={qrCodeValue}
                            viewBox="0 0 256 256"
                            data-testid="fulfilment-qr-code"
                        />
                    </Card>
                )}
                {additionalReceiptText && !embedMode && (
                    <Text fontWeight="bold" fontSize="md" as="h2" textAlign="center">
                        {additionalReceiptText}
                    </Text>
                )}
                {fiscalizationFooter && (
                    <Box pb="16px">
                        <Text fontWeight="normal" fontSize="sm" as="p" textAlign="center" whiteSpace="pre">
                            {fiscalizationFooter}
                        </Text>
                    </Box>
                )}
            </VStack>
        </ReceiptPage>
    );
}

export default Receipt;
