import React from 'react';
import { Text, useTheme, VStack } from '@chakra-ui/react';
import { useSetState, useStateData } from 'providers/AppStateProvider';
import { PaymentInput, ServicePaymentType, ServiceChargeConfiguration } from 'utils/generated';
import MultiSelectCardGrid from 'components/MultiSelectCardGrid';
import CurrencyFormat from 'components/CurrencyFormat';
import useSplitInEqualParts from 'helpers/useSplitInEqualParts';

function AddTip() {
    const { state } = useStateData();
    const { setPayment } = useSetState();
    const { colors } = useTheme();
    const { percentageLow, percentageMedium, percentageHigh } = state?.location
        ?.serviceChargeConfiguration as ServiceChargeConfiguration;
    const { servicePayment } = state?.payment as PaymentInput;

    const { amountDueForSelected } = useSplitInEqualParts();

    const calculateTipAtPercentage = (tipPercentage: number) =>
        Math.floor(amountDueForSelected * (tipPercentage / 100));

    const tipCard = (percent: number) => ({
        value: percent,
        element: (
            <VStack
                p="3"
                m="1"
                spacing="-1"
                border={`${
                    calculateTipAtPercentage(percent) === servicePayment?.amount
                        ? '2px solid #3063E9'
                        : `1.5px solid ${colors.neutral.n400}`
                }`}
                borderRadius="8px"
            >
                <Text fontWeight="bold" fontSize="xl">{`${percent}%`}</Text>
                <Text fontSize="md">
                    <CurrencyFormat amount={amountDueForSelected + calculateTipAtPercentage(percent)} />
                </Text>
            </VStack>
        ),
    });

    const tipAmounts = [percentageLow, percentageMedium, percentageHigh]
        .filter((entry) => entry !== undefined)
        .map((percent) => tipCard(Number(percent)));

    const updatePayment = (tipAmount: number, newAmount: number) => {
        if (tipAmount === 0) {
            setPayment({
                amount: newAmount,
                servicePayment: undefined,
            });
        }
        setPayment({
            amount: newAmount,
            servicePayment: {
                amount: tipAmount,
                type: ServicePaymentType.Tip,
                description: `Tip`,
            },
        });
    };

    const handleTipUpdate = (percentage: number) => {
        const tip = calculateTipAtPercentage(percentage);
        if (tip !== servicePayment?.amount) updatePayment(tip, tip + amountDueForSelected);
        if (tip === servicePayment?.amount) updatePayment(0, amountDueForSelected);
    };

    return (
        <>
            <Text mb="8" fontSize="sm" align="center">
                Price includes taxes and tips
            </Text>
            <Text mb="3" align="center">
                100% of tip goes into your server&apos;s pocket
            </Text>
            <MultiSelectCardGrid<number> items={tipAmounts} onChange={handleTipUpdate} />
        </>
    );
}

export default AddTip;
