import { Chance } from 'chance';
import { captureException } from 'helpers/sentryLogger';

export class DeviceHelper {
    deviceId = localStorage.getItem('device-id');

    app = 'CONSUMER';

    chance = new Chance();

    getDeviceInfo() {
        if (!this.deviceId) {
            this.deviceId = this.chance.guid({ version: 4 });
            localStorage.setItem('device-id', this.deviceId);
        }
        const deviceInfo = {
            'device-id': this.deviceId,
            app: this.app,
        };
        return deviceInfo;
    }
}

export function oapFetcher<TData, TVariables>(query: string, variables?: TVariables) {
    const deviceHelper = new DeviceHelper();

    return async (): Promise<TData> => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL as string, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...deviceHelper.getDeviceInfo(),
                    Authorization: 'not_a_token',
                },
                body: JSON.stringify({ query, variables }),
            }).catch((err) => err);

            if (!response.ok) {
                throw new Error(`Response not ok for, [query]: ${query} `);
            }
            const json = await response.json();
            if (json.errors) {
                throw json.errors;
            }
            if (json.data) {
                return json.data;
            }
            throw new Error(`Newtork error, [query]: ${query}`);
        } catch (err) {
            captureException(err);
            throw err;
        }
    };
}

export default oapFetcher;
