import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import LocaleProvider from 'providers/LocaleProvider';
import AppStateProvider from 'providers/AppStateProvider';
import Router from 'router/Router';

import theme from 'utils/theme/theme';
import { BrowserRouter } from 'react-router-dom';
import { ApiError, ApiErrorInfoObject } from 'utils/generated';
import { errorToast, infoToast } from 'helpers/ToastHelper';
import { captureException } from 'helpers/sentryLogger';

const queryClient = new QueryClient();

if (process.env.REACT_APP_ENABLE_SERVICE_WORKER === 'true') {
    (async () =>
        (await import('mocks/browser')).worker.start({ waitUntilReady: true, onUnhandledRequest: 'bypass' }))();
}

const checkIsCapturedError = (error: ApiErrorInfoObject) => {
    return error?.errorInfo?.captured === true;
};

function handlerError(errors: unknown) {
    if (Array.isArray(errors))
        errors?.forEach((error: ApiError) => {
            if (checkIsCapturedError(error as ApiErrorInfoObject)) {
                return;
            }
            if (error?.errorType === 'PAYMENT.AMOUNT_INVALID') {
                queryClient.refetchQueries({
                    queryKey: ['GetOrderAndLocation'],
                });
                infoToast({
                    title: 'It looks like the order has been updated, check the order screen for details',
                });
                return;
            }
            // TODO look for better wording for this toast
            if (error.errorType === 'PAYMENT.REQUEST_INVALID') {
                queryClient.refetchQueries({
                    queryKey: ['GetOrderAndLocation'],
                });
                infoToast({ title: 'It looks like the order has been updated, check the order screen for details' });
                return;
            }
            errorToast({ title: error.message });
        });
    if (!Array.isArray(errors)) {
        captureException(errors);
    }
}

queryClient.setDefaultOptions({
    queries: {
        retry: false,
        refetchOnWindowFocus: false,
        onError: handlerError,
    },
    mutations: {
        retry: false,
        onError: handlerError,
    },
});

function App() {
    return (
        <BrowserRouter>
            <ChakraProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <LocaleProvider>
                        <AppStateProvider>
                            <Router />
                        </AppStateProvider>
                    </LocaleProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </ChakraProvider>
        </BrowserRouter>
    );
}

export default App;
