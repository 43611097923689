import React, { useCallback, useEffect, useRef } from 'react';
import { Button, Flex, VStack, Spinner } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import { ApiError, ApiErrorInfoObject, useCreatePaymentMutation } from 'utils/generated';
import Page from 'components/Page';
import { useQueryClient } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { captureBreadcrumb, mutationBreadcrumb } from 'helpers/sentryLogger';
import useReshapedPayment from 'helpers/useReshapedPayment';
import { useStateData } from 'providers/AppStateProvider';
import ErrorAlert from 'components/ErrorAlert';
import LoadingSpinner from 'components/LoadingSpinner';
import { errorToast, infoToast } from 'helpers/ToastHelper';

function ProcessPayment() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { reshape } = useReshapedPayment();
    const { state } = useStateData();

    const hasHadError = useRef(false);

    const { merchantId, locationId, orderId, intentId } = useParams();

    const orderUrl = `/merchant/${merchantId}/location/${locationId}/order/${orderId}`;

    const successUrl = `${orderUrl}/${intentId}/success`;

    const resetOrderQuery = useCallback(() => {
        queryClient.refetchQueries({
            queryKey: ['GetOrderAndLocation'],
        });
    }, [queryClient]);

    const handleErrors = useCallback(
        (errors: ApiError[]) => {
            const alreadyCapturedError = (error: ApiErrorInfoObject) => error.errorInfo?.captured === true;
            return new Promise((_, reject) => {
                if (!Array.isArray(errors)) {
                    errorToast({ title: 'Payment failed. Please retry' });
                    reject(errors);
                } else {
                    const hasCapturedError = errors.every((error) => alreadyCapturedError(error as ApiErrorInfoObject));
                    if (hasCapturedError) {
                        navigate(successUrl, { replace: true });
                    } else {
                        queryClient.refetchQueries({
                            queryKey: ['GetOrderAndLocation'],
                        });
                        infoToast({
                            title: 'It looks like the order has been updated, check the order screen for details',
                        });
                        navigate(orderUrl, { replace: true });
                    }
                    reject(errors);
                }
            });
        },
        [navigate, orderUrl, queryClient, successUrl]
    );

    const onSuccess = useCallback(() => {
        resetOrderQuery();
        navigate(successUrl, { replace: true });
    }, [navigate, successUrl, resetOrderQuery]);

    const { mutateAsync, isError, isLoading } = useCreatePaymentMutation({
        onSuccess,
        onError: (error) => {
            handleErrors(error);
        },
        onMutate: ({ paymentProcessorData }) => {
            captureBreadcrumb(
                mutationBreadcrumb({
                    mutationName: 'createPayment',
                    intentSent: paymentProcessorData?.stripePaymentIntentId,
                })
            );
        },
    });

    const capturePayment = useCallback(() => {
        if (state?.payment?.amount) {
            const reshapedPayment = reshape(state?.payment, intentId);
            if (reshapedPayment?.paymentProcessorData) {
                mutateAsync({
                    ...reshapedPayment,
                    paymentProcessorData: reshapedPayment.paymentProcessorData,
                });
            }
        }
    }, [intentId, mutateAsync, reshape, state?.payment]);

    if (isError) hasHadError.current = true;

    useEffect(() => {
        // On first load we try to capture the payment. As there will be no error at this point.
        // We also need to check that this is not a reloaded page via the new piece of state.
        if (!isError && !isLoading && state?.paymentAuthorised) {
            capturePayment();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (state?.paymentAuthorised !== true) {
        resetOrderQuery();
        return <Navigate to={orderUrl} replace state={{ redirectReason: 'Failed to capture', intentId }} />;
    }

    return (
        <Page
            title={hasHadError.current ? 'Payment error' : undefined}
            footer={
                hasHadError.current && (
                    <Button disabled={isLoading} isFullWidth data-cy="payAll" onClick={capturePayment}>
                        {isLoading ? (
                            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" />
                        ) : (
                            'Retry'
                        )}
                    </Button>
                )
            }
        >
            {hasHadError.current ? (
                <Flex justifyContent="center" alignContent="center">
                    <VStack mt="20" spacing="16">
                        <WarningIcon color="#E53E3E" h="20" w="20" />
                        <ErrorAlert message="Sorry we've had a problem processing your payment. Please retry." />
                    </VStack>
                </Flex>
            ) : (
                <LoadingSpinner />
            )}
        </Page>
    );
}

export default ProcessPayment;
