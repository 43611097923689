import React from 'react';
import { Image, Button, Heading, Text, VStack, HStack } from '@chakra-ui/react';
import { HiLightningBolt } from 'react-icons/hi';
import Page from 'components/Page';
import { useStateData } from 'providers/AppStateProvider';
import LoadingSpinner from 'components/LoadingSpinner';
import { useParams } from 'react-router-dom';
import useSplitInEqualParts from 'helpers/useSplitInEqualParts';
import ShareButton from 'components/ShareButton';
import CurrencyFormat from 'components/CurrencyFormat';
import OrderHelper from 'helpers/OrderHelper';
import { OrderV0 } from 'utils/generated';

function PaymentSuccess() {
    const { state } = useStateData();
    const { merchantId, locationId, orderId } = useParams();

    const orderUrl = `${window.location.origin}/merchant/${merchantId}/location/${locationId}/order/${orderId}/`;

    const { numberOfpartyMembersToPay, amountDueForSelected } = useSplitInEqualParts();

    const orderHelper = new OrderHelper(state?.order as OrderV0);

    const paymentItemsTotal = () => {
        if (!state?.payment?.lineItems) return 0;
        return (
            state?.payment?.lineItems?.reduce(
                (acc, selectedItem) => selectedItem.amount * selectedItem.quantity + acc,
                0
            ) ?? 0
        );
    };

    const serviceTotal = orderHelper.serviceChargeDue(paymentItemsTotal());

    const hasItemsLeftToPay =
        orderHelper.orderHasItemPayment() && orderHelper.numberOfItems() > orderHelper.numberOfItemsPaid();

    const handleViewReceiptClick = () => {
        window.location.href = orderUrl;
    };

    if (!state) return <LoadingSpinner />;

    return (
        <Page
            footer={
                <Text mb="4" align="center">
                    Powered by SumUp
                </Text>
            }
        >
            <VStack>
                <Image mt="16" mb="8" width="200px" src="/img/success-graphic.svg" />

                <Heading p="2" size="md" as="h1">
                    {!hasItemsLeftToPay && numberOfpartyMembersToPay === 0 ? 'Your bill is paid!' : 'Payment confirmed'}
                </Heading>

                {!hasItemsLeftToPay && numberOfpartyMembersToPay === 0 && (
                    <Text pb="8" align="center" fontSize="md">
                        Thank you! The servers know you&apos;ve paid, feel free to leave whenever you&apos;re ready
                    </Text>
                )}
                {(numberOfpartyMembersToPay > 0 || hasItemsLeftToPay) && (
                    <VStack pb="5" spacing="0">
                        <Text>Your payment is complete</Text>
                        <HStack pb="4">
                            <Text align="center" fontSize="md">
                                Your table still has&nbsp;
                                <strong>
                                    <CurrencyFormat amount={amountDueForSelected + serviceTotal} />
                                </strong>
                                &nbsp;to pay
                            </Text>
                        </HStack>
                        <ShareButton url={`${orderUrl}split`} nameOfSharedItem="payment link" />
                    </VStack>
                )}
                <Button
                    data-cy="viewReceipt"
                    variant="outline"
                    leftIcon={<HiLightningBolt />}
                    onClick={handleViewReceiptClick}
                >
                    View receipt
                </Button>
            </VStack>
        </Page>
    );
}

export default PaymentSuccess;
