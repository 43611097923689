import { useImmerReducer } from 'use-immer';
import { WritableDraft } from 'immer/dist/types/types-external';
import { OrderPaymentType, ServicePaymentType } from 'utils/generated';
import { BasketActions, BasketState, BasketUpdates } from './BasketReducer.types';
import { calculateTipAtPercentage } from '../Order.utils';

function calculatedAmountOrRemaining(draft: WritableDraft<BasketState>, calculatedAmount: number) {
    if (draft.totalRemaining <= 0) {
        // eslint-disable-next-line no-param-reassign
        draft.payment.amount = 0;
    } else {
        // eslint-disable-next-line no-param-reassign
        draft.payment.amount = Math.min(draft.totalRemaining, Math.ceil(calculatedAmount));
        // eslint-disable-next-line no-param-reassign
        draft.hasTotalBeenLowered = draft.payment.amount === draft.totalRemaining;
    }
}

function calculatePaymentForParties(draft: WritableDraft<BasketState>) {
    const partySize = draft.payment.orderPaymentIntention.partySize as number;
    const parties = draft.payment.parties as number;

    const totalDueForParty = draft.orderTotal / partySize;
    const amountDueForSelectedParties = totalDueForParty * parties;

    // eslint-disable-next-line no-param-reassign
    draft.payment.amount = Math.ceil(amountDueForSelectedParties);
}

function setupFullPayment(draft: WritableDraft<BasketState>, payload: { amount: number }) {
    // eslint-disable-next-line no-param-reassign
    draft.payment = {
        ...draft.payment,
        amount: payload.amount,
        lineItems: [],
        servicePayment: null,
        orderPaymentIntention: {
            orderPaymentType:
                draft.orderTotal === draft.totalRemaining ? OrderPaymentType.Full : OrderPaymentType.Amount,
        },
    };
}

function setupItemPayment(draft: WritableDraft<BasketState>) {
    // eslint-disable-next-line no-param-reassign
    draft.payment = {
        ...draft.payment,
        amount: 0,
        lineItems: [],
        servicePayment: null,
        orderPaymentIntention: {
            orderPaymentType: OrderPaymentType.Item,
        },
    };
}

function setupPartyPayment(draft: WritableDraft<BasketState>) {
    // eslint-disable-next-line no-param-reassign
    draft.payment = {
        ...draft.payment,
        amount: 0,
        parties: 1,
        lineItems: [],
        servicePayment: null,
        orderPaymentIntention: {
            orderPaymentType: OrderPaymentType.Party,
            partySize: 1,
        },
    };
    calculatePaymentForParties(draft);
}

function addItem(draft: WritableDraft<BasketState>, payload: { item: { posId: string; amount: number } }) {
    // Don't allow users to add items if the order is already paid for
    if (draft.totalRemaining <= 0) return;

    if (!draft.payment.lineItems) {
        // eslint-disable-next-line no-param-reassign
        draft.payment.lineItems = [{ ...payload.item, quantity: 1 }];
        return;
    }
    const index = draft.payment.lineItems.findIndex((item) => item.posId === payload.item.posId) ?? -1;
    if (index < 0 && draft.payment.lineItems) {
        // eslint-disable-next-line no-param-reassign
        draft.payment.lineItems = [...draft.payment.lineItems, { ...payload.item, quantity: 1 }];
        return;
    }
    // eslint-disable-next-line no-param-reassign
    draft.payment.lineItems[index] = {
        ...draft.payment.lineItems[index],
        amount: draft.payment.lineItems[index].amount + payload.item.amount,
        quantity: draft.payment.lineItems[index].quantity + 1,
    };
}

function removeItem(draft: WritableDraft<BasketState>, payload: { item: { posId: string; amount: number } }) {
    if (!draft.payment.lineItems) {
        return;
    }
    const index = draft.payment.lineItems.findIndex((item) => item.posId === payload.item.posId) ?? -1;
    if (index < 0) {
        return;
    }
    if (draft.payment.lineItems[index].quantity === 0) {
        return;
    }
    // Filter out any items that the quantity would be zero
    if (draft.payment.lineItems[index].quantity - 1 <= 0) {
        // eslint-disable-next-line no-param-reassign
        draft.payment.lineItems = draft.payment.lineItems.filter((item) => item.posId !== payload.item.posId);
        return;
    }

    // eslint-disable-next-line no-param-reassign
    draft.payment.lineItems[index] = {
        ...draft.payment.lineItems[index],
        amount: draft.payment.lineItems[index].amount - payload.item.amount,
        quantity: draft.payment.lineItems[index].quantity - 1,
    };
}

function addAllItems(
    draft: WritableDraft<BasketState>,
    payload: { items: Array<{ posId: string; amount: number; quantity: number }> }
) {
    // eslint-disable-next-line no-param-reassign
    draft.payment.lineItems = payload.items.map((item) => ({ ...item, amount: item.amount * item.quantity }));
}

function serviceChargeForSelectedLines({
    serviceChargeEnabled,
    totalServiceCharge,
    lineItemsTotal,
    orderTotal,
}: {
    serviceChargeEnabled: boolean;
    totalServiceCharge: number;
    lineItemsTotal: number;
    orderTotal: number;
}) {
    if (!serviceChargeEnabled || totalServiceCharge === 0) return 0;

    if (lineItemsTotal === 0) return 0;

    // Implementation ignores previous payments
    // TODO handle previous payments
    // Get the total without the service charge
    const totalWithoutServiceCharge = orderTotal - totalServiceCharge;

    // Get the percentage of the order that the selected lines make up
    const percentageOfOrder = lineItemsTotal / totalWithoutServiceCharge;

    // Get the percentage of the sale these items are for
    return Math.ceil(percentageOfOrder * totalServiceCharge);
}

function calculatePaymentTotalFromLineItems(draft: WritableDraft<BasketState>) {
    const lineItemsTotal = draft.payment.lineItems?.reduce((acc, item) => acc + item.amount, 0) || 0;

    const serviceChargeDue = serviceChargeForSelectedLines({
        serviceChargeEnabled: draft.serviceChargeEnabled,
        totalServiceCharge: draft.totalServiceCharge,
        lineItemsTotal,
        orderTotal: draft.orderTotal,
    });

    // eslint-disable-next-line no-param-reassign
    draft.serviceChargeDue = serviceChargeDue;

    const totalAmount = lineItemsTotal + serviceChargeDue;

    if (draft.serviceChargeEnabled) {
        // eslint-disable-next-line no-param-reassign
        draft.payment.servicePayment = {
            amount: serviceChargeDue,
            type: ServicePaymentType.ServiceCharge,
        };
    }

    // eslint-disable-next-line no-param-reassign
    draft.payment.amount = Math.ceil(totalAmount);
}

export function useBasketReducer(initialState: BasketState) {
    return useImmerReducer<BasketState, BasketUpdates>((draft, action) => {
        switch (action.type) {
            case BasketActions.ClearBasket:
                // eslint-disable-next-line no-param-reassign
                draft = initialState;
                break;
            case BasketActions.DeselectAllItems:
                // eslint-disable-next-line no-param-reassign
                draft.payment.lineItems = undefined;
                calculatePaymentTotalFromLineItems(draft);
                break;
            case BasketActions.SetupFullPayment:
                setupFullPayment(draft, action.payload);
                break;
            case BasketActions.SetupPartyPayment:
                setupPartyPayment(draft);
                break;
            case BasketActions.SetupItemPayment:
                setupItemPayment(draft);
                break;
            case BasketActions.IncreasePeople:
                if (draft.payment.orderPaymentIntention.orderPaymentType !== OrderPaymentType.Party) {
                    setupPartyPayment(draft);
                }
                // eslint-disable-next-line no-param-reassign
                draft.payment.orderPaymentIntention.partySize =
                    Number(draft.payment.orderPaymentIntention.partySize) + 1;

                calculatePaymentForParties(draft);
                break;
            case BasketActions.DecreasePeople:
                if (draft.payment.orderPaymentIntention.orderPaymentType !== OrderPaymentType.Party) {
                    setupPartyPayment(draft);
                }
                // eslint-disable-next-line no-param-reassign
                draft.payment.orderPaymentIntention.partySize =
                    Number(draft.payment.orderPaymentIntention.partySize) - 1;

                calculatePaymentForParties(draft);
                break;
            case BasketActions.AddPartyMember: {
                if (draft.payment.orderPaymentIntention.orderPaymentType !== OrderPaymentType.Party) {
                    setupPartyPayment(draft);
                }
                // eslint-disable-next-line no-param-reassign
                draft.payment.parties = Number(draft.payment.parties) + 1;

                calculatePaymentForParties(draft);
                break;
            }
            case BasketActions.RemovePartyMember: {
                if (draft.payment.orderPaymentIntention.orderPaymentType !== OrderPaymentType.Party) {
                    setupPartyPayment(draft);
                }
                // eslint-disable-next-line no-param-reassign
                draft.payment.parties = Number(draft.payment.parties) - 1;
                calculatePaymentForParties(draft);
                break;
            }
            case BasketActions.AddTip: {
                // Resetting the tip state
                if (draft.payment.servicePayment?.amount) {
                    // eslint-disable-next-line no-param-reassign
                    draft.payment.amount -= draft.payment.servicePayment.amount;
                }

                // TODO review this and see if this is correct
                const tipAmount = calculateTipAtPercentage(action.payload.tipPercentage, draft.payment.amount);
                // eslint-disable-next-line no-param-reassign
                draft.payment.servicePayment = {
                    type: ServicePaymentType.Tip,
                    amount: tipAmount,
                    description: 'Tip',
                };

                // eslint-disable-next-line no-param-reassign
                draft.payment.amount += tipAmount;

                break;
            }
            case BasketActions.RemoveTip:
                // eslint-disable-next-line no-param-reassign
                draft.payment.servicePayment = undefined;
                break;
            case BasketActions.AddItem:
                addItem(draft, action.payload);
                calculatePaymentTotalFromLineItems(draft);
                break;
            case BasketActions.RemoveItem:
                removeItem(draft, action.payload);
                calculatePaymentTotalFromLineItems(draft);
                break;
            case BasketActions.SelectAllItems:
                // eslint-disable-next-line no-param-reassign
                draft.payment.lineItems = undefined;
                addAllItems(draft, action.payload);
                calculatePaymentTotalFromLineItems(draft);
                break;
            case BasketActions.RemainingOrAmount:
                // eslint-disable-next-line no-param-reassign
                draft.hasTotalBeenLowered = false;
                calculatedAmountOrRemaining(draft, Math.ceil(draft.payment.amount));
                break;
            default:
                break;
        }
    }, initialState);
}

export default useBasketReducer;
