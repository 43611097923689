import React from 'react';
import { Heading, Stack } from '@chakra-ui/react';
import { Player } from '@lottiefiles/react-lottie-player';

function LoadingSpinner() {
    return (
        <Stack h="70vh" align="center" justify="center">
            <Player
                autoplay
                controls={false}
                style={{ height: '300px', width: '300px' }}
                loop
                src="/animations/oapLoadingAnimation.json"
            />
            <Heading size="md" fontWeight="bold" as="h2">
                Fetching your bill…
            </Heading>
        </Stack>
    );
}

export default LoadingSpinner;
