import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import { useRoutesNavigate } from 'hooks/useRoutesNavigate';
import { PaymentState, useCheckPaymentStatusMutation } from '../../../utils/generated';
import { useOrderInfo } from '../../contexts/OrderProvider';
import { errorToast } from '../../../helpers/ToastHelper';
import { useBasket } from '../../contexts/basket-provider/BasketProvider';

function CheckPaymentStatus() {
    const { paymentId } = useParams();
    const { basket } = useBasket();
    const {
        merchant: { id: merchantId },
        addPaymentToOrder,
    } = useOrderInfo();
    const navigate = useRoutesNavigate();

    const { mutate } = useCheckPaymentStatusMutation({
        onSuccess: ({ checkPaymentStatus }) => {
            switch (checkPaymentStatus.state) {
                case PaymentState.Completed: {
                    addPaymentToOrder(checkPaymentStatus);
                    navigate('PaymentSuccess');
                    break;
                }
                case PaymentState.Failed:
                    errorToast({ title: 'Something went wrong while making your payment.' });
                    navigate('Overview');
                    break;
                case PaymentState.InProgress:
                    errorToast({ title: 'Something went wrong while making your payment, please try again.' });
                    if (basket.payment.amount === 0) {
                        // If our basket is empty, we've refreshed the page and we're not getting a payment status
                        // The user needs to recreate the basket
                        // TODO Consider putting the whole basket state inside a cookie to better handle refreshes
                        navigate('Overview');
                    } else if (paymentId) {
                        navigate('Payment', { paymentId });
                    } else {
                        navigate('Overview');
                    }
                    break;
                default: {
                    break;
                }
            }
        },
        onError: () => {
            navigate('Error');
        },
        retry: 3,
    });

    useEffect(() => {
        if (paymentId && merchantId) {
            mutate({
                paymentId,
                merchantId,
            });
        } else {
            navigate('Error');
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [merchantId, paymentId]);

    return (
        <Flex h="90vh" align="center" justify="center" flexDirection="column">
            <Spinner />
            <Text mt={4}>Processing payment</Text>
        </Flex>
    );
}

export default CheckPaymentStatus;
