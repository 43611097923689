import React from 'react';
import { Button, Text } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { HiLightningBolt } from 'react-icons/hi';

interface Props {
    url: string;
}

function MenuButton({ url }: Props) {
    return (
        <Button
            isFullWidth
            leftIcon={<HiLightningBolt color="black" />}
            rightIcon={<ArrowForwardIcon color="black" />}
            variant="outline"
            borderWidth="2px"
            borderRadius="2xl"
            px="8px"
            onClick={() => window.location.replace(url)}
        >
            <Text align="start" w="100%" fontWeight="400">
                View Menu
            </Text>
        </Button>
    );
}

export default MenuButton;
