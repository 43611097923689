import React, { Fragment } from 'react';
import { Grid, Text, Box, useTheme, Flex, Heading, GridItem, Badge, HStack } from '@chakra-ui/react';
import { LineItem } from 'utils/generated';
import { SplitItem, SplitItems } from 'typings/splitItem';
import CurrencyFormat from './CurrencyFormat';

interface Props {
    lineItems: LineItem[];
    tableName: string;
    lineItemDetails: SplitItems;
}

function PaidItem({ remainingQuantity, purchasedQuantity }: SplitItem) {
    return purchasedQuantity > 0 ? (
        <Badge borderRadius="2xl" m="0" fontWeight="400" textTransform="unset" px="1.5" py="0.5">
            {remainingQuantity !== 0 ? `${purchasedQuantity} paid` : 'Paid'}
        </Badge>
    ) : null;
}

function OrderItemGrid({ lineItems, tableName, lineItemDetails }: Props) {
    const { colors } = useTheme();

    return (
        <>
            <Flex px="4" pt="4" direction="row" align="center" justify="space-between">
                <Heading size="md" as="h1" data-cy="orderReference">
                    {`${tableName.match('^[0-9]*$') ? `Table ${tableName}` : tableName}`}
                </Heading>
            </Flex>
            <Box data-cy="orderItemGrid" overflow="auto">
                <Grid px="4" templateColumns="1fr 10fr 1fr" gap="1">
                    {lineItems?.map((lineItem) => (
                        <Fragment key={lineItem.posId}>
                            <Text fontWeight="500" fontSize="sm">
                                {lineItem.quantity}x
                            </Text>

                            <Text fontWeight="500" fontSize="sm">
                                {lineItem.name}
                            </Text>
                            <GridItem>
                                <HStack justifyContent="space-between">
                                    <PaidItem {...lineItemDetails[lineItem.posId!]} />
                                    <Text w="full" fontWeight="600" fontSize="sm" align="right">
                                        <CurrencyFormat amount={lineItem.quantity * lineItem.amount} />
                                    </Text>
                                </HStack>
                            </GridItem>
                            <GridItem colSpan={2} colStart={2}>
                                {lineItem.childItems &&
                                    lineItem.childItems.map((childItem, childItemIndex) => (
                                        <Text
                                            key={childItem.name + String(childItemIndex)}
                                            gridColumnStart="2"
                                            color={colors.neutral.n700}
                                            fontSize="sm"
                                        >
                                            {childItem.quantity > 1 && `${childItem.quantity}x`} {childItem.name}
                                        </Text>
                                    ))}
                            </GridItem>
                        </Fragment>
                    ))}
                </Grid>
            </Box>
        </>
    );
}

export default OrderItemGrid;
