import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { useSetState } from 'providers/AppStateProvider';
import LoadingOrder from 'components/LoadingOrder';
import { PosSystem, useGetPosOrderHeaderQuery } from 'utils/generated';
import { isPosSystem } from 'typings/typePredicates';
import LoadingSpinner from 'components/LoadingSpinner';

function ReceiptQR() {
    const navigate = useNavigate();
    const location = useLocation();

    const params = new URLSearchParams(location.search);

    const orderId = params.get('orderId');
    const locationId = params.get('locationId');
    const posSystem = params.get('posSystem');

    const { data, isFetching, isLoading, error } = useGetPosOrderHeaderQuery(
        {
            posOrderId: orderId!,
            posLocationId: locationId!,
            posSystem: PosSystem[posSystem! as keyof typeof PosSystem],
        },
        {
            enabled: !!orderId && isPosSystem(posSystem) && !!locationId,
            onSuccess: (res) => {
                navigate(
                    `/merchant/${res.getPosOrderHeader.merchantId}/location/${res.getPosOrderHeader.locationId}/order/${res.getPosOrderHeader.id}`
                );
            },
        }
    );

    if (error) {
        navigate(
            `/error/merchant/${data?.getPosOrderHeader.merchantId}/location/${data?.getPosOrderHeader.locationId}/order/${data?.getPosOrderHeader.id}/cant-load-order`
        );
    }

    if (isFetching || isLoading) return <LoadingOrder />;

    return <LoadingSpinner />;
}

export default ReceiptQR;
