import React, { Fragment } from 'react';
import { Button, Divider, Grid, GridItem, Heading, HStack, Table, Tbody, Td, Text, Tr, VStack } from '@chakra-ui/react';
import CurrencyFormat, { FormattedCurrencyNumber } from 'components/CurrencyFormat';
import { Navigate } from 'react-router-dom';
import Chance from 'chance';
import { useBasket } from 'pages/contexts/basket-provider/BasketProvider';
import { useOrderInfo } from '../../contexts/OrderProvider';
import Page from '../../../components/Page';
import TipsCards from './components/TipsCards';
import { BasketActions } from '../../contexts/basket-provider/BasketReducer.types';
import { useRoutesNavigate } from '../../../hooks/useRoutesNavigate';
import { useDisplayData } from '../../contexts/DisplayDataProvider';
import colors from '../../../utils/theme/colors';
import { commaSeparateItems, showQuantityIfMoreThanOne } from '../../../helpers/textFormating';
import { ServicePaymentType } from '../../../utils/generated';
import { PaidForText } from '../overview/components/OverviewPayments';
import PaymentRow from '../../../components/PaymentRow';

function ReviewPayment() {
    const { order, location } = useOrderInfo();
    const navigate = useRoutesNavigate();
    const { basket, updateBasket } = useBasket();
    const { lineItemsWithQuantities, concatenatedPayments } = useDisplayData();
    const { amount, servicePayment } = basket.payment;
    const { hasTotalBeenLowered } = basket;

    const amountWithoutTip = servicePayment?.amount ? amount - servicePayment.amount : amount;
    const serviceChargeAlreadyAdded = order.serviceCharge?.amount == null ? false : order?.serviceCharge?.amount > 0;

    const totalAmountToPay = amount;

    // If the basket is fresh, redirect them and remove this from the history stack
    if (amount === 0) {
        // TODO refactor this to use named routes
        console.warn('No amount set, basket is fresh, redirecting to overview', basket);
        return <Navigate to="../" replace />;
    }

    const serviceChargeConfig = location?.serviceChargeConfiguration;

    const handleTipUpdate = (tipPercentage: number) => {
        updateBasket({ type: BasketActions.AddTip, payload: { tipPercentage } });
    };

    const navigateToPay = () => {
        navigate('Payment', { paymentId: Chance().guid() });
    };

    return (
        <Page
            showBack
            title={serviceChargeAlreadyAdded ? 'Review Payment' : 'Leave a tip'}
            footer={
                <Button isFullWidth data-cy="payByCard" variant="outline" onClick={() => navigateToPay()}>
                    Pay by card
                </Button>
            }
        >
            <Text mt="16" textAlign="center">
                Total amount is
            </Text>
            <Heading mb="2" data-cy="totalForParty" align="center" size="3xl">
                <FormattedCurrencyNumber amount={totalAmountToPay} currency={order.currency} />
            </Heading>
            <Text mb="8" fontSize="sm" align="center">
                Price includes taxes and tips. <br />
                {hasTotalBeenLowered && 'Your total has been lowered because other people have made payments.'}
            </Text>
            {!serviceChargeAlreadyAdded && (
                <TipsCards
                    amount={amountWithoutTip}
                    serviceChargeConfig={serviceChargeConfig}
                    handleTipUpdate={handleTipUpdate}
                    currency={order.currency}
                />
            )}
            {basket.payment.lineItems && basket.payment.lineItems.length > 0 && (
                <>
                    <Heading my="5" mx="2" data-cy="totalForParty" size="sm">
                        {`Selected Item${basket.payment.lineItems.length > 0 ? 's' : ''}`}
                    </Heading>
                    <Grid px="2" templateColumns="1fr 15fr 1fr" gap="1">
                        {basket.payment.lineItems.map((item) => (
                            <Fragment key={item.posId}>
                                <Text fontWeight="500" fontSize="sm">
                                    {item.quantity}x
                                </Text>
                                <Text fontWeight="500" fontSize="sm">
                                    {lineItemsWithQuantities[item.posId].name}
                                </Text>
                                <Text fontWeight="600" fontSize="sm" align="right">
                                    <CurrencyFormat amount={item.amount * item.quantity} />
                                </Text>
                                {lineItemsWithQuantities[item.posId].childItems && (
                                    <GridItem colSpan={2} colStart={2}>
                                        <HStack spacing="1" fontSize="xs" textOverflow="ellipsis">
                                            {lineItemsWithQuantities[item.posId].childItems.map(
                                                (childItem, childItemIndex) => (
                                                    <Text
                                                        key={childItem.name + String(childItemIndex)}
                                                        color={colors.neutral.n600}
                                                    >
                                                        {showQuantityIfMoreThanOne(childItem.quantity, childItem.name)}
                                                        {commaSeparateItems(
                                                            childItemIndex,
                                                            lineItemsWithQuantities[item.posId].childItems.length
                                                        )}
                                                    </Text>
                                                )
                                            )}
                                        </HStack>
                                    </GridItem>
                                )}
                            </Fragment>
                        ))}
                    </Grid>
                    {basket.payment.servicePayment?.amount && (
                        <VStack p="2">
                            <Divider />
                            <HStack w="full" alignItems="center" justify="space-between">
                                <Text fontWeight="500" fontSize="sm">
                                    {basket.payment.servicePayment.type === ServicePaymentType.Tip
                                        ? 'Tips for items'
                                        : 'Service charge for items'}
                                </Text>
                                <Text fontWeight="700" fontSize="sm">
                                    <FormattedCurrencyNumber
                                        amount={basket.payment.servicePayment?.amount ?? 0}
                                        currency={basket.payment.currency}
                                    />
                                </Text>
                            </HStack>
                        </VStack>
                    )}
                </>
            )}
            {hasTotalBeenLowered && (
                <Table variant="simple" size="sm">
                    <Tbody>
                        <Tr fontWeight={700}>
                            <Td border="none">Previous Payments</Td>
                        </Tr>
                        {concatenatedPayments.map((x, index) => (
                            <PaymentRow
                                key={String(x.amount + x.name + index)}
                                title={x.name}
                                paidFor={PaidForText(x)}
                                lastFour={x.lastFour}
                                amount={x.amount}
                            />
                        ))}
                    </Tbody>
                </Table>
            )}
        </Page>
    );
}

export default ReviewPayment;
