import React, { Fragment, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Divider, Grid, GridItem, Heading, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import { useStateData } from 'providers/AppStateProvider';
import StripePayByWallet from 'components/stripe/StripePayByWallet';
import Page from 'components/Page';
import { OrderV0, PaymentInput, PaymentLineItemInput, ServiceChargeConfiguration } from 'utils/generated';
import CurrencyFormat from 'components/CurrencyFormat';
import MinimumSpendButtonWrapper from 'components/MinimumSpendButtonWrapper';
import OrderHelper from 'helpers/OrderHelper';
import colors from 'utils/theme/colors';
import { commaSeparateItems, showQuantityIfMoreThanOne } from 'helpers/textFormating';
import AddTip from './AddTip';
import { PayOutletContext } from './Pay';

function PaymentSelect() {
    const navigate = useNavigate();
    const { state } = useStateData();
    const { stripePaymentIntent, isLoading } = useOutletContext<PayOutletContext>();
    const { amount } = state?.payment as PaymentInput;
    const { enabled } = state?.location?.serviceChargeConfiguration as ServiceChargeConfiguration;
    const { serviceCharge } = state?.order as OrderV0;
    const [paymentInProgressCallback, setPaymentInProgressCallback] = useState(false);

    const tipsEnabled = enabled && (!serviceCharge || serviceCharge.amount === 0);

    const orderHelper = new OrderHelper(state?.order as OrderV0);

    const items = state?.payment?.lineItems;
    const itemDetails = orderHelper.lineItemsDetails();

    const isMoreThanOneItem = (selectedLineItems: PaymentLineItemInput[]) => {
        return selectedLineItems.length > 1 && selectedLineItems[0].quantity > 1;
    };

    return (
        <Page
            showBack
            title={tipsEnabled ? 'Leave a tip ❤️' : 'Review Payment'}
            footer={
                <MinimumSpendButtonWrapper>
                    {!isLoading && <StripePayByWallet paymentInProgressCallback={setPaymentInProgressCallback} />}
                    <Button
                        isFullWidth
                        disabled={isLoading || paymentInProgressCallback}
                        data-cy="payByCard"
                        variant="outline"
                        onClick={() => navigate(`${stripePaymentIntent.id}/card`, { replace: true })}
                    >
                        {paymentInProgressCallback ? (
                            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" />
                        ) : (
                            'Pay by card'
                        )}
                    </Button>
                </MinimumSpendButtonWrapper>
            }
        >
            <Text mt="16" textAlign="center">
                Total amount is
            </Text>
            <Heading mb="2" data-cy="totalForParty" align="center" size="3xl">
                <CurrencyFormat amount={amount} />
            </Heading>
            {tipsEnabled ? (
                <AddTip />
            ) : (
                <Text fontSize="md" align="center">
                    Price includes taxes and service charge
                </Text>
            )}
            {items && (
                <>
                    <Heading my="5" mx="2" data-cy="totalForParty" size="sm">
                        {`Selected Item${isMoreThanOneItem(items) ? 's' : ''}`}
                    </Heading>
                    <Grid px="2" templateColumns="1fr 15fr 1fr" gap="1">
                        {items.map((item) => (
                            <Fragment key={item.posId}>
                                <Text fontWeight="500" fontSize="sm">
                                    {item.quantity}x
                                </Text>
                                <Text fontWeight="500" fontSize="sm">
                                    {itemDetails[item.posId].name}
                                </Text>
                                <Text fontWeight="600" fontSize="sm" align="right">
                                    <CurrencyFormat amount={item.amount * item.quantity} />
                                </Text>
                                {itemDetails[item.posId].childItems && (
                                    <GridItem colSpan={2} colStart={2}>
                                        <HStack spacing="1" fontSize="xs" textOverflow="ellipsis">
                                            {itemDetails[item.posId].childItems.map((childItem, childItemIndex) => (
                                                <Text
                                                    key={childItem.name + String(childItemIndex)}
                                                    color={colors.neutral.n600}
                                                >
                                                    {showQuantityIfMoreThanOne(childItem.quantity, childItem.name)}
                                                    {commaSeparateItems(
                                                        childItemIndex,
                                                        itemDetails[item.posId].childItems.length
                                                    )}
                                                </Text>
                                            ))}
                                        </HStack>
                                    </GridItem>
                                )}
                            </Fragment>
                        ))}
                    </Grid>
                    {state.order?.serviceCharge && (
                        <VStack p="2">
                            <Divider />
                            <HStack w="full" alignItems="center" justify="space-between">
                                <Text fontWeight="500" fontSize="sm">
                                    Service charge for items
                                </Text>
                                <Text fontWeight="700" fontSize="sm">
                                    <CurrencyFormat amount={state?.payment?.servicePayment?.amount ?? 0} />
                                </Text>
                            </HStack>
                        </VStack>
                    )}
                </>
            )}
        </Page>
    );
}

export default PaymentSelect;
