import React from 'react';
import { Text, Image, VStack } from '@chakra-ui/react';
import Page from 'components/Page';

function NoMenu() {
    return (
        <Page showBack>
            <VStack mt="128px" spacing="4">
                <Image my="15" mx="8" src="/img/lock-graphic.svg" />
                <Text as="strong" align="center" fontSize="md">
                    There is no menu available for this location.
                </Text>
                <Text align="center" fontSize="md">
                    Please ask your server for assistance.
                </Text>
            </VStack>
        </Page>
    );
}

export default NoMenu;
