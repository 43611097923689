import React from 'react';
import { Tr, Td, Flex, Text } from '@chakra-ui/react';
import CurrencyFormat from 'components/CurrencyFormat';

interface Props {
    title: string;
    amount: number;
    lastFour?: string;
    paidFor?: string;
}

function TitleAmountRow({ title, lastFour, amount, paidFor }: Props) {
    return (
        <Tr>
            <Td border="none">
                <Flex direction="column" align="flex-start">
                    <Text fontWeight="400" fontSize="sm">
                        {title} {paidFor}
                    </Text>
                </Flex>
            </Td>
            <Td border="none" textAlign="right">
                <Flex flexDirection="row" align="flex-end" justify="flex-end">
                    {lastFour && (
                        <Text mt="1" mr="3" fontWeight="200" fontSize="smaller">
                            {lastFour ? `Card ${lastFour}` : null}
                        </Text>
                    )}
                    <CurrencyFormat amount={amount} />
                </Flex>
            </Td>
        </Tr>
    );
}

export default TitleAmountRow;

TitleAmountRow.defaultProps = {
    lastFour: undefined,
    paidFor: undefined,
};
