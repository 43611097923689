import React, { useEffect } from 'react';
import Page from 'components/Page';
import { useNavigate, useParams } from 'react-router-dom';
import { VStack, Text, Image, Flex } from '@chakra-ui/react';
import Selector from 'components/Selector';
import { useSetState, useStateData } from 'providers/AppStateProvider';
import { OrderPaymentType } from 'utils/generated';
import OrderHelper from 'helpers/OrderHelper';
import { infoToast } from 'helpers/ToastHelper';
import useCurrentPath, { MATCH_ROUTES } from 'helpers/useCurrentPath';

function SplitMethodSelect() {
    const { merchantId, locationId, orderId } = useParams();
    const navigate = useNavigate();

    const splitByItemAvailable = process.env.REACT_APP_SPLIT_BY_ITEM_DISABLED !== 'true';

    const { setPayment } = useSetState();
    const { state } = useStateData();

    const orderHelper = new OrderHelper(state!.order!);

    const lineItems = state?.payment?.lineItems;

    const { isCurrentPath } = useCurrentPath(MATCH_ROUTES.SPLIT_METHOD);
    // Resets the query if returning to the order screen.
    useEffect(() => {
        if (isCurrentPath && lineItems) setPayment({ lineItems: undefined });
    }, [isCurrentPath, lineItems, setPayment]);

    const hasDiscount = orderHelper.hasDiscount();

    const hasPartyPayments = orderHelper.orderHasPartyPayments();

    const hasItemPayments = orderHelper.orderHasItemPayment();

    const splitByItemDisabled = hasPartyPayments || hasDiscount;

    const toSplitByItem = () => {
        setPayment({
            parties: undefined,
            lineItems: [],
            orderPaymentIntention: {
                orderPaymentType: OrderPaymentType.Item,
                partySize: undefined,
            },
        });
        navigate('by-item');
    };

    const toSplitByParty = () => {
        if (!hasItemPayments && !hasPartyPayments) {
            setPayment({
                parties: 1,
                orderPaymentIntention: {
                    orderPaymentType: OrderPaymentType.Party,
                    partySize: 1,
                },
            });
            navigate('by-equal-parts');
        }
        if (!hasItemPayments && hasPartyPayments) {
            setPayment({
                parties: 1,
            });
            navigate('by-equal-parts/my-part');
        }
    };

    if (!splitByItemDisabled && hasItemPayments) toSplitByItem();
    if (!hasItemPayments && hasPartyPayments) toSplitByParty();

    const handleClickPartySplit = () => {
        if (hasItemPayments) {
            infoToast({
                title: 'The order has already been split by item',
                description: 'Please select pay for your items to continue',
            });
        } else {
            toSplitByParty();
        }
    };

    const handleClickItemSplit = () => {
        if (splitByItemDisabled) {
            infoToast({
                title: hasDiscount ? 'Order cannot be split by item.' : 'The order has already been split by party',
                description: hasDiscount
                    ? 'Please speak to your server or select pay in equal parts to continue'
                    : 'Please select pay in equal parts to continue',
            });
        }
        if (!splitByItemDisabled) {
            toSplitByItem();
        }
    };

    return (
        <Page
            showBack
            backUrl={`/merchant/${merchantId}/location/${locationId}/order/${orderId}`}
            title="Split the bill"
        >
            <Flex flexDirection="column" h="full" flexGrow="1" justify="center">
                <Image h="44" src="/img/split-the-bill.svg" />
                <Text pt="9" pb="0" textAlign="center" fontSize="2xl" fontWeight="bold">
                    How do you want to split?
                </Text>
                <Text pb="8" textAlign="center">
                    Choose your prefered method
                </Text>
                <VStack w="full" spacing="4">
                    {splitByItemAvailable && (
                        <Selector disabled={splitByItemDisabled} onClick={handleClickItemSplit}>
                            <Text p="0" textAlign="left" fontWeight="bold">
                                Pay for your items.
                            </Text>
                            <Text p="0" textAlign="left" fontSize="sm">
                                Guests select the items to pay
                            </Text>
                        </Selector>
                    )}
                    <Selector disabled={hasItemPayments} onClick={handleClickPartySplit}>
                        <Text fontWeight="bold">Pay in equal parts.</Text>
                        <Text fontSize="sm">Guests pay the same amount</Text>
                    </Selector>
                </VStack>
            </Flex>
        </Page>
    );
}

export default SplitMethodSelect;
