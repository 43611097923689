import React from 'react';
import { Table, Tbody } from '@chakra-ui/react';
import TitleAmountRow from 'components/TitleAmountRow';
import OrderHelper from 'helpers/OrderHelper';
import PaymentRow from 'components/PaymentRow';

interface Props {
    order: OrderHelper;
}

function OrderTotalTable({ order }: Props) {
    const serviceTotal = order.serviceTotal();
    const subtotal = order.subtotal();
    const due = order.totalDue();

    const PaidForText = (parties?: number) => {
        if (!parties) return '';
        if (parties > 1) return ` - Paid for ${parties} people`;
        if (parties === 1) return ' - Paid for 1 person';
        return '';
    };

    return (
        <Table variant="simple" size="sm">
            <Tbody>
                <TitleAmountRow title="Subtotal" amount={subtotal} />
                {serviceTotal !== 0 && <TitleAmountRow title="Service Charge" amount={serviceTotal} />}
                <TitleAmountRow title="Total" amount={subtotal + serviceTotal} strong />

                {order.payments().map((x, index) => (
                    <PaymentRow
                        key={String(x.amount + x.title + index)}
                        title={x.title}
                        paidFor={PaidForText(x.parties)}
                        lastFour={x.lastFour}
                        amount={x.amount}
                    />
                ))}

                <TitleAmountRow title="Due" amount={due} strong />
            </Tbody>
        </Table>
    );
}

export default OrderTotalTable;
