import React, { Button, VStack } from '@chakra-ui/react';
import { useBasket } from 'pages/contexts/basket-provider/BasketProvider';
import { useRoutesNavigate } from 'hooks/useRoutesNavigate';
import { useDisplayData } from '../../contexts/DisplayDataProvider';
import Page from '../../../components/Page';
import { FormattedCurrencyNumber } from '../../../components/CurrencyFormat';
import ShareButton from '../../../components/ShareButton';
import MenuButton from '../../order/MenuButton';
import Card from '../../../components/Card';
import OverviewPayments from './components/OverviewPayments';
import { useOrderInfo } from '../../contexts/OrderProvider';
import OverviewOrderItems from './components/OverviewOrderItems';
import { BasketActions } from '../../contexts/basket-provider/BasketReducer.types';

function Overview() {
    const { totalDue } = useDisplayData();
    const { order, location, merchant } = useOrderInfo();
    const { updateBasket } = useBasket();
    const navigate = useRoutesNavigate();

    const navigateToTips = () => {
        updateBasket({ type: BasketActions.SetupFullPayment, payload: { amount: totalDue } });
        navigate('ReviewPayment');
    };

    return (
        <Page
            title={merchant?.businessName}
            titleLarge
            footer={
                <VStack w="100%" spacing="4">
                    {totalDue > 0 && (
                        <>
                            <Button isFullWidth data-cy="payAll" onClick={() => navigateToTips()}>
                                Pay &nbsp;
                                <FormattedCurrencyNumber amount={totalDue} currency={order.currency} />
                            </Button>
                            <Button
                                isFullWidth
                                variant="outline"
                                data-cy="split-by-select"
                                onClick={() => navigate('SplitSelect')}
                            >
                                Split
                            </Button>
                        </>
                    )}
                    {totalDue <= 0 && (
                        <ShareButton
                            isFullWidth
                            variant="secondary"
                            url={`${window.location.origin}/merchant/${merchant.id}/location/${location.id}/order/${order.id}`}
                        />
                    )}
                </VStack>
            }
        >
            <VStack gap="1">
                {location?.menuUrl && <MenuButton url={location?.menuUrl} />}
                <Card>
                    <OverviewOrderItems tableName={order.posTableName || ''} currency={order.currency} />
                    <OverviewPayments />
                </Card>
            </VStack>
        </Page>
    );
}
export default Overview;
