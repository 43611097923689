import React from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import { Outlet, useParams } from 'react-router-dom';
import { useGetPublicLocationQuery } from 'utils/generated';

function LocationContainer() {
    const { merchantId, locationId, orderId } = useParams();
    // Check if loading only location and merchant info
    const locationRoute = !!merchantId && !!locationId && !orderId;

    const { isLoading } = useGetPublicLocationQuery(
        { merchantId: merchantId!, locationId: locationId! },
        {
            enabled: locationRoute,
        }
    );

    if (isLoading) return <LoadingSpinner />;

    return <Outlet />;
}

export default LocationContainer;
