import { useStateData } from 'providers/AppStateProvider';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

function SplitByEqualPartContainer() {
    const params = useParams();
    const { state } = useStateData();
    const { merchantId, locationId, orderId } = params;
    const navigate = useNavigate();

    const splitByPartyAvailable =
        (state?.payment && state?.payment?.parties !== undefined && state?.payment?.amount > 30) || false;
    useEffect(() => {
        if (!splitByPartyAvailable) navigate(`/merchant/${merchantId}/location/${locationId}/order/${orderId}`);
    }, [locationId, merchantId, navigate, orderId, splitByPartyAvailable, state]);
    return <Outlet />;
}

export default SplitByEqualPartContainer;
