import React from 'react';
import { Heading, Flex, Box, HStack, VStack, Link, Text, IconButton } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

interface Props {
    showBack?: boolean;
    backUrl?: string;
    title?: string;
    titleLarge?: boolean;
    footer?: React.ReactNode;
    children: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    onBackClick?: () => void;
}

function Page({ showBack, title, titleLarge, footer, backUrl, onBackClick, children }: Props) {
    const navigate = useNavigate();
    return (
        <Flex w="100%" flexFlow="column" wrap="nowrap" maxW="md" overflow="hidden" ml="auto" mr="auto" height="100%">
            {title && (
                <HStack w="100%" alignItems="center" px="4" py="4" overflow="auto">
                    {showBack && (
                        <IconButton
                            variant="ghost"
                            p={0}
                            m={0}
                            aria-label="Back"
                            onClick={() => {
                                if (onBackClick != null) {
                                    onBackClick();
                                } else {
                                    if (backUrl) navigate(backUrl);
                                    if (!backUrl) navigate(-1);
                                }
                            }}
                            icon={<ArrowBackIcon cursor="pointer" h="6" w="6" variant="secondary" />}
                        />
                    )}
                    {title && (
                        <Heading size={titleLarge ? 'md' : 'sm'} fontWeight={titleLarge ? 'bold' : undefined} as="h1">
                            {title}
                        </Heading>
                    )}
                </HStack>
            )}
            <Box w="100%" p="4" flex={2} overflow="auto">
                {children}
            </Box>
            {footer && (
                <VStack w="100%" spacing="4" p="4">
                    {footer}
                </VStack>
            )}
            <Box as="footer" fontSize="14px" textAlign="center" mb="2">
                <Link href="https://sumup.com/privacy/">Privacy Policy</Link>
                <Text as="span" mx="1">
                    {' • '}
                </Text>
                <Link href="https://www.sumup.com/en-us/terms/">Terms and Conditions</Link>
            </Box>
        </Flex>
    );
}

export default Page;

Page.defaultProps = {
    showBack: false,
    backUrl: undefined,
    title: undefined,
    titleLarge: false,
    footer: undefined,
};
