import React from 'react';
import { IoIosSend } from 'react-icons/io';
import { Button, Icon, useToast } from '@chakra-ui/react';

interface Props {
    url: string;
    variant?: 'link' | 'secondary' | 'outline';
    nameOfSharedItem?: string;
    isFullWidth?: boolean;
}

function ShareButton({ url, nameOfSharedItem, isFullWidth, variant }: Props) {
    const toast = useToast();

    const handleShare = () => {
        if (navigator.share) {
            navigator
                .share({
                    url,
                })
                .then(() => {
                    toast({
                        title: 'Sharing',
                        status: 'info',
                    });
                });
        } else {
            toast({
                title: 'Sharing is not allowed in your browser',
                status: 'info',
            });
        }
    };

    return (
        <Button isFullWidth={isFullWidth} variant={variant} leftIcon={<Icon as={IoIosSend} />} onClick={handleShare}>
            {`Share ${nameOfSharedItem}`}
        </Button>
    );
}

export default ShareButton;

ShareButton.defaultProps = {
    nameOfSharedItem: '',
    isFullWidth: false,
    variant: 'link',
};
