import { Box, Flex, Heading, Link, Text, VStack } from '@chakra-ui/react';
import React from 'react';

function ReceiptPage({
    internalReference,
    reference,
    receiptHeader,
    footer,
    children,
    embedMode,
    fiscalizationHeader,
}: {
    children: React.ReactNode;
    internalReference?: string | null;
    reference?: string | null;
    receiptHeader?: string | null;
    footer?: React.ReactNode;
    embedMode?: boolean;
    fiscalizationHeader?: string | null;
}) {
    return (
        <Flex w="100%" flexFlow="column" wrap="nowrap" maxW="md" overflow="hidden" ml="auto" mr="auto" height="100%">
            <Box w="100%" p="4" flex={2} overflow="auto">
                <VStack mt={embedMode ? '0px' : '35px'} w="100%" alignItems="center" px="4" py="4" overflow="auto">
                    {internalReference && (
                        <Heading size="xl" w="100" textAlign="center" fontWeight="bold" as="h1">
                            {internalReference}
                        </Heading>
                    )}
                    <Heading size="lg" w="100" textAlign="center" fontWeight="bold" as="h2" minHeight="36px">
                        {reference}
                    </Heading>
                    {!embedMode &&
                        receiptHeader &&
                        receiptHeader.split('\n').map((headerLine) => {
                            const trimmed = headerLine.trim();
                            return trimmed.length ? (
                                <Text fontWeight="500" fontSize="sm">
                                    {trimmed}
                                </Text>
                            ) : null;
                        })}
                    {fiscalizationHeader && (
                        <Text whiteSpace="pre" fontSize="sm">
                            {fiscalizationHeader}
                        </Text>
                    )}
                </VStack>
                {children}
                {footer && (
                    <VStack w="100%" spacing="4" p="4">
                        {footer}
                    </VStack>
                )}
                {!embedMode && (
                    <Box as="footer" fontSize="14px" textAlign="center" mb="2">
                        <Link href="https://sumup.com/privacy/">Privacy Policy</Link>
                        <Text as="span" mx="1">
                            {' • '}
                        </Text>
                        <Link href="https://www.sumup.com/en-us/terms/">Terms and Conditions</Link>
                    </Box>
                )}
            </Box>
        </Flex>
    );
}

export default ReceiptPage;

ReceiptPage.defaultProps = {
    footer: undefined,
    internalReference: 'No reference provided',
    reference: null,
    receiptHeader: null,
    embedMode: false,
    fiscalizationHeader: null,
};
