import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';

const options: UseToastOptions = {
    duration: 3000,
    isClosable: true,
    position: 'bottom',
};

export const infoToast = createStandaloneToast({
    defaultOptions: {
        ...options,
        status: 'info',
    },
});

export const errorToast = createStandaloneToast({
    defaultOptions: {
        ...options,
        status: 'error',
    },
});

export const warningToast = createStandaloneToast({
    defaultOptions: {
        ...options,
        status: 'warning',
    },
});

export const successToast = createStandaloneToast({
    defaultOptions: {
        ...options,
        status: 'success',
    },
});
